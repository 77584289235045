
import { defineComponent } from 'vue';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import TeleportWrapper from '@/_shared/components/general/TeleportWrapper.vue';

export default defineComponent({
  components: { BaseIcon, BaseButton, TeleportWrapper },
  props: {
    title: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    cancelHidden: {
      type: Boolean,
      default: true,
    },
    fromRestoreModal: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: 'Confirm',
    },
    confirmDisabled: {
      type: Boolean,
      default: false,
    },
    confirmHidden: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    iconUrl: {
      type: String,
    },
    iconSvg: {
      type: String,
    },
    footer: {
      type: Boolean,
      default: true,
    },
    header: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 600,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    modalClass: {
      type: String,
      default: '',
    },
    contentHeight: {
      type: Number,
      required: false,
    },
    cardDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeModal', 'confirm'],

  setup(props) {
    const cardStyle = {
      width: props.fullWidth ? '100%' : `${props.width}px`,
    };

    const contentStyle = {
      height: props.contentHeight ? `${props.contentHeight}px` : undefined,
    };

    return {
      cardStyle,
      contentStyle,
    };
  },
});

