import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

import { onBeforeRouteUpdate, useRoute } from 'vue-router';
import useEventListener from '@/_shared/Composables/event';
import getDynamicLinks from '@/_shared/helpers/clientTabs';
import { dataPointsStore } from '@/_shared/store/dataPoints';
import useServiceFilteringStore from '@/_shared/store/serviceFiltering';
import { clientsRelationsStore } from '@/_shared/store/Relation';
import BaseNavigationItem from '@/_shared/components/navigation/BaseNavigationItem.vue';
import TimelineDropdownItem from '@/_shared/components/navigation/TimelineDropdownItem.vue';
import { onBeforeMount } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClientTopBar',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const props = __props;
const route = useRoute();

const {
  clientStore,
  dynamicLinks,
  timelineLinks,
} = getDynamicLinks(+props.clientId, window.familyPortal, route);

useEventListener(window, 'ClientStateUpdate', () => clientStore.reFetchById(+props.clientId));
useEventListener(window, 'ClientRelationsChanged', () => clientsRelationsStore.refreshRelations(+props.clientId));

onBeforeMount(async () => {
  maybeUpdateDropdownLink();
});

onBeforeRouteUpdate(async (to, from) => {
  // only fetch the user if the id changed as maybe only the query or the hash changed
  maybeUpdateDropdownLink();
  if (to.params.clientId !== from.params.clientId) {
    clientStore.byId(+to.params.clientId);
    dataPointsStore.byId(+to.params.clientId);
    useServiceFilteringStore().$reset(+to.params.clientId, (to.name as string).includes('client.assessments'));
  }
});

const maybeUpdateDropdownLink = () => {
  const dropdownIndex = dynamicLinks.value.findIndex((link) => link.timelineDropdown);
  if (dropdownIndex === -1) return;
  let currentLink = timelineLinks.value.find((link) => link.to.name === route.name);
  if (!currentLink && route.params.instanceId) {
    // try nested routes
    let parentRoute = '';
    if (route.path.includes('instances')) {
      parentRoute = 'client.timeline.body_map';
    } else if (route.path.includes('events')) {
      parentRoute = 'client.events';
    }
    currentLink = timelineLinks.value.find((link) => link.to.name === parentRoute);
  }
  if (currentLink && currentLink.to.name !== dynamicLinks.value[dropdownIndex].to.name) {
    dynamicLinks.value[dropdownIndex] = currentLink;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dynamicLinks), (link) => {
    return (_openBlock(), _createElementBlock("div", _mergeProps({ ref_for: true }, _ctx.$attrs, {
      key: link.name
    }), [
      (!link.timelineDropdown)
        ? (_openBlock(), _createBlock(BaseNavigationItem, {
            key: 0,
            to: link.to,
            icon: link.icon,
            text: link.name,
            forceActive: link.activeOnChildren
          }, null, 8, ["to", "icon", "text", "forceActive"]))
        : _createCommentVNode("", true),
      (link.timelineDropdown)
        ? (_openBlock(), _createBlock(TimelineDropdownItem, {
            key: 1,
            link: link,
            "client-id": __props.clientId
          }, null, 8, ["link", "client-id"]))
        : _createCommentVNode("", true)
    ], 16))
  }), 128))
}
}

})