import IInteraction from '@/timeline/types/IInteraction';
import { Need } from '@/timeline/types/Need';
import {
  isPast, isSameDay, isWithinInterval, subWeeks,
} from 'date-fns';

export type NextReviewDateValidationAlerts = 'no_date_note_alert' | 'current_date_note_alert' | 'past_date_note_alert' | null;
export type ReviewValidationErrors = ('invalid_new_review_date_1' | 'invalid_new_review_date_2' | 'empty_note_banner')[] | null;

export const validateNextReviewDate = (date: string | null): NextReviewDateValidationAlerts => {
  if (!date) {
    return 'no_date_note_alert';
  }
  const theDate = new Date(date);
  if (isSameDay(theDate, new Date())) {
    return 'current_date_note_alert';
  }
  if (isPast(theDate)) {
    return 'past_date_note_alert';
  }
  return null;
};

export const isInLastTwoWeeks = (date: string | null) => {
  if (!date) {
    return false;
  }

  return isWithinInterval(new Date(date), { start: subWeeks(new Date(), 2), end: new Date() });
};

export const validateNeed = (need: Need, review: IInteraction): { errors?: ReviewValidationErrors, alert?: NextReviewDateValidationAlerts} => {
  if (!isInLastTwoWeeks(review.finishAt)) {
    return {
      errors: ['invalid_new_review_date_1', 'invalid_new_review_date_2'],
    };
  }
  if (!review.notePublic) {
    return {
      errors: ['empty_note_banner'],
    };
  }
  const alert = validateNextReviewDate(need.nextReviewOn);
  if (alert) {
    return {
      alert,
    };
  }

  return {};
};

export const buildNeedToEdit = (need: Need): Need => {
  const result = {
    ...need,
    identifiedNeed: need.identifiedNeed ?? '',
    plannedOutcomes: need.plannedOutcomes ?? '',
    howToAchiveOutcomes: need.howToAchiveOutcomes ?? '',
  };
  result.needProvidedServicesAttributes = [...need.needProvidedServices];
  result.levelOfNeed = need.levelOfNeed[1]!;
  result.riskAssessmentsAttributes = need.riskAssessments.map((r) => ({
    id: r.id,
    likelihood: r.likelihood,
    impact: r.impact,
    actions: r.actions,
    description: r.description,
    archived: r.archived,
  }))
    .sort((a, b) => {
      const idA = a.id || 0;
      const idB = b.id || 0;
      return idA - idB;
    });

  return result;
};
