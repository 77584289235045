import { reactive, shallowReactive } from 'vue';
import { IdObject } from '@/_shared/types/IdObject';
import { until } from '@/_shared/services/UseUtils';
import { isBefore } from 'date-fns';

export default abstract class CollectionStore<T extends IdObject, Response extends (Record<string, unknown> | Record<string, unknown>[])> {
  protected collection: Record<number, T> = shallowReactive({});

  private idsToFetch = new Set<number>();

  private initializing = false;

  private initialized = false;

  private readonly timeout: number | null;

  constructor(timeout: number | null = null) {
    this.timeout = timeout;
  }

  async initialize() {
    if (!this.initialized && !this.initializing) {
      this.initializing = true;
      const initialLoad = this.initialLoad();
      initialLoad.then((resp) => {
        if (resp && Object.keys(resp).length) this.storeData(resp);
        this.initialized = true;
        this.initializing = false;
      });
    }
    if (!this.initialized && this.initializing) {
      while (this.initializing) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => {
          setTimeout(resolve, 100);
        });
      }
    }
  }

  protected initialLoad(): Promise<Response> {
    return Promise.resolve({} as Response);
  }

  storeData(resp: Response) {
    this.extractData(resp)?.forEach((dataObject) => {
      this.upsert(dataObject);
      this.idsToFetch.delete(dataObject.id);
    });
  }

  // TODO implement error and unauthorized handling
  // TODO abstract methods for Loading/Unauthorized/Unavailable/Errored items
  upsert(dataObject: T) {
    dataObject.$lastFetchedAt = new Date();
    if (this.collection[dataObject.id]) {
      Object.assign(this.collection[dataObject.id], reactive(dataObject));
      return;
    }
    this.collection[dataObject.id] = reactive(dataObject) as T;
  }

  byId(id: number): T {
    if (!(id in this.collection) || this.isBeforeTimeout(id)) {
      this.collection[id] = reactive({ $lastFetchedAt: new Date() }) as T;
      this.debouncedFetch(id);
    }
    // this actually returns reactive<T>, T is only for TypeScript compilation check
    return this.collection[id] as T;
  }

  private isBeforeTimeout(id: number) {
    return this.timeout && isBefore(this.collection[id].$lastFetchedAt!, new Date(new Date().getTime() - this.timeout));
  }

  async asyncById(id: number): Promise<T> {
    const element = this.byId(id);
    await until(() => this.initialized && !this.idsToFetch.has(id));
    return element;
  }

  async asyncByIds(ids: number[]): Promise<T[]> {
    return Promise.all(ids.map((id: number) => this.asyncById(id)));
  }

  reFetchById(id: number): void {
    if ((id in this.collection)) {
      this.debouncedFetch(id);
    } else this.byId(id);
  }

  byIds(ids: number[]): T[] {
    return ids.map((id) => this.byId(id));
  }

  /**
   * ApiCient call that fetches
   * @param ids Array of ids to fetch
   */
  protected abstract fetch(ids: number[]): Promise<Response>;

  protected abstract extractData(resp: Response): T[];

  protected DEBOUNCE_MS = 50;

  private debouncedFetch = this.debouncedAggregate(this.fetch, this.DEBOUNCE_MS);

  private debouncedAggregate(fetchMulti: (ids: number[]) => Promise<Response>, wait = 50) {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    return (id: number) => {
      this.initialize();
      this.idsToFetch.add(id);
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        timeout = null;
        if (!this.initialized) {
          this.debouncedFetch(id);
          return;
        }
        if (!this.idsToFetch.size) return;
        const idsToFetchArray = Array.from(this.idsToFetch);
        fetchMulti(idsToFetchArray)
          .then((resp) => this.storeData(resp))
          .then(() => {
            idsToFetchArray.forEach((fetchedId) => this.idsToFetch.delete(fetchedId));
          });
      }, wait);
    };
  }

  // TODO: Starting to work on collection store returning a pinia store so we can keep the same access patterns
  // static init<O extends IdObject, R extends Record<string, unknown>>(store: CollectionStore<O, R>, storeType: string) {
  //   store.initialize();
  //   const properties: Record<string, unknown> = {};
  //   let fullStore = {};
  //   let storeObject = store;
  //   do {
  //     console.log('storeObject', storeObject);
  //     fullStore = {
  //       ...fullStore,
  //       ...storeObject,
  //     };
  //     // eslint-disable-next-line no-cond-assign
  //   } while ((storeObject = Object.getPrototypeOf(storeObject)));
  //   properties[storeType] = properties.collection;
  //   delete properties.collection;
  //   return defineStore(storeType, () => store);
  // }
}
