import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import type { EmitsOptions, FunctionalComponent } from 'vue';

import { RouteAuthContext } from '@/_shared/router/types';
import BasePlaceHolderView from '@/_shared/components/routing/BasePlaceHolderView.vue';
import ClientForm from '@/clients/components/ClientForm.vue';
import ClientPlusButton from '@/clients/components/ClientPlusButton.vue';
import componentBaseOnFT from '@/_shared/router/ComponentHelper';
import GpConnectRightPanelMain from '@/medical_record_integrations/gp_connect/GpConnectRightPanelMain.vue';
import AssessmentsLeftPanel from '@/assessments/AssessmentsLeftPanel.vue';
import AssessmentsSelector from '@/assessments/AssessmentsSelector.vue';
import InteractionPlusButton from '@/timeline/components/InteractionPlusButton.vue';
import InteractionsLeftPanel from '@/timeline/components/InteractionsLeftPanel.vue';
import InteractionsRightPanelMain from '@/timeline/InteractionsRightPanelMain.vue';
import ClientTopBar from '@/clients/components/ClientTopBar.vue';
import MedicalRecords from '@/medical_record_integrations/MedicalRecords.vue';
import LondonCareRecordMainPanelVue from '@/medical_record_integrations/london_care_record/components/LondonCareRecordMainPanel.vue';
import { InteractionSelector, ComponentsWrapper } from '@/timeline/components';
import useInteractionsStore from '@/_shared/store/interactions';
import maybeRedirectByClientState from '@/_shared/router/helpers/routesClientHelper';
import { clientStore } from '@/_shared/store/clients';
import { dataPointsStore } from '@/_shared/store/dataPoints';
import useAssessmentStore from '@/_shared/store/assessments';
import useServiceFilteringStore from '@/_shared/store/serviceFiltering';
import TimelinePlusButtonWrapper from '@/timeline/components/TimelinePlusButtonWrapper.vue';
import useUserStore from '@/_shared/store/user';
import BodyMapTimeline from '@/timeline/components/BodyMap/BodyMapTimeline.vue';
import InstancesTimelineFComponent from '@/timeline/components/InstancesTimelineFComponent';
import { instancesStore } from '@/_shared/store/Instances';
import CarePlanConsentRightPanel from '@/careplan/CarePlanConsentRightPanel.vue';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import TransferClientsPlusButton from '@/client_profile/components/TransferClientsPlusButton.vue';
import TransferClientsForm from '@/client_profile/components/TransferClientsForm.vue';
import EventsTimeline from '@/events/components/EventsTimeline.vue';
import EventViewTimeline from '@/events/components/EventViewTimeline.vue';
import EventSelector from '@/events/components/EventSelector.vue';
import EventsPlusButtonWrapper from '@/events/components/EventsPlusButtonWrapper.vue';
import BodyMapTimelineWrapper from '@/timeline/components/BodyMapTimelineWrapper.vue';
import CarePlanReviewRightPanel from '@/timeline/components/CarePlanReview/CarePlanReviewRightPanel.vue';
import BodyMapViewTimeline from '@/timeline/components/BodyMap/BodyMapViewTimeline.vue';

export const clientRoutes: RouteRecordRaw[] = [
  {
    path: '/clients',
    components: {
      TopBar: BasePlaceHolderView,
    },
    meta: { needSyncAngular: true },
    children: [
      {
        path: '',
        components: {
          Action: async () => componentBaseOnFT('aoFabMenu', ClientPlusButton),
          SideBar: BasePlaceHolderView,
        },
        // to do add it to build client store
        // beforeEnter: fetchOrgUnitClients
        // main right panel
        children: [
          {
            path: '',
            name: 'clientList',
            component: BasePlaceHolderView,
            meta: { isAngular: true },
          },
          {
            path: 'new/:type',
            name: 'clientList.new',
            component: ClientForm,
            props: true,
          },
        ],
        props: true,
      },
    ],
    props: true,
  },
  {
    path: '/clients/:clientId(\\d+)',
    components: {
      TopBar: async () => componentBaseOnFT('aoFabMenu', ClientTopBar),
    },
    beforeEnter: async (to: RouteLocationNormalized) => {
      // on id change is handled inside top ClientTopBar onRouteUpdate
      const c = await clientStore.asyncById(+to.params.clientId);
      if (!c.id) return false;
      dataPointsStore.byId(+to.params.clientId);
      if (useUserStore().getFeatureToggle('bodyMapInstances')) {
        useServiceFilteringStore().$reset(+to.params.clientId, false);
      }
      return true;
    },
    meta: { needSyncAngular: true },
    children: [
      {
        // angular needs the trailing slash but seems like vue needs full path specified to
        path: '/clients/:clientId(\\d+)/',
        components: {
          Action: TimelinePlusButtonWrapper,
          SideBar: InteractionsLeftPanel,
        },
        children: [
          {
            path: '',
            name: 'client.timeline',
            props: true,
            component: BodyMapTimelineWrapper,
            beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
              await loadModuleLanguageFileAsync('events');
              return maybeRedirectByClientState(to, from).then((result) => {
                if (result === true && !(to.name as string).includes('client.timeline')) {
                  useInteractionsStore().$reset();
                }
                return result;
              });
            },
            meta: { isAngular: true },
          },
          {
            path: 'instances',
            name: 'client.timeline.body_map',
            components:
            {
              default: BodyMapTimelineWrapper,
              SideBarInnerRouteView: BodyMapTimeline,
            },
            props: true,
          },
          {
            path: 'instances/:instanceId(\\d+)',
            name: 'client.timeline.body_map.instance',
            components:
              {
                default: BodyMapTimelineWrapper,
                SideBarInnerRouteView: BodyMapViewTimeline,
              },
            props: true,
            // todo this is temporary until InstanceViewTimeline is refactored
            beforeEnter: async (to: RouteLocationNormalized) => {
              await instancesStore.asyncById(+to.params.clientId);
              await loadModuleLanguageFileAsync('timeline');
            },
          },
        ],
        props: false,

      },
      {
        path: 'interactions',
        alias: [
          'instances/:instanceId(\\d+)?/interactions',
          'events/:instanceId(\\d+)?/interactions',
        ],
        components: {
          Action: TimelinePlusButtonWrapper,
          SideBar: InteractionsLeftPanel,
        },
        beforeEnter: async (to: RouteLocationNormalized) => {
          await loadModuleLanguageFileAsync('events');
          useServiceFilteringStore().$reset(+to.params.clientId, false);
          if (to.params?.instanceId) await instancesStore.asyncById(+to.params.clientId);
        },
        // main right panel
        children: [
          {
            path: 'new',
            name: 'client.timeline.interaction_new',
            components: {
              default: InteractionsRightPanelMain,
              SideBarInnerRouteView: InstancesTimelineFComponent as FunctionalComponent<{route: RouteLocationNormalized}, EmitsOptions>,
            },
            props: {
              default: (route: RouteLocationNormalized) => ({ serviceId: route.query.serviceId, clientId: route.params.clientId }),
              SideBarInnerRouteView: (route: RouteLocationNormalized) => ({ route }),
            },
          },
          {
            path: ':id(\\d+)',
            name: 'client.timeline.interaction',
            beforeEnter: async (to: RouteLocationNormalized) => {
              if (+to.params.instanceId) {
                return instancesStore.interactionRelatedToInstance(+to.params.clientId, +to.params.instanceId, +to.params.id);
              }
              return true;
            },
            components: {
              default: InteractionsRightPanelMain,
              SideBarInnerRouteView: InstancesTimelineFComponent as FunctionalComponent<{route: RouteLocationNormalized}, EmitsOptions>,
            },
            props: {
              default: true,
              SideBarInnerRouteView: (route: RouteLocationNormalized) => ({ route }),
            },
          },
          {
            path: '/clients/:clientId/:instanceId(\\d+)?/needinteractions/:id(\\d+)',
            alias: '/clients/:clientId/instances/:instanceId(\\d+)?/needinteractions/:id(\\d+)',
            name: 'client.timeline.needinteraction',
            components: {
              default: async () => componentBaseOnFT('vueCareplanReview', CarePlanReviewRightPanel),
              SideBarInnerRouteView: InstancesTimelineFComponent as FunctionalComponent<{route: RouteLocationNormalized}, EmitsOptions>,
            },
            props: {
              default: true,
              SideBarInnerRouteView: (route: RouteLocationNormalized) => ({ route }),
            },
          },
          {
            path: 'service_selector',
            name: 'client.timeline.service_selector',
            components: {
              default: InteractionSelector,
              SideBarInnerRouteView: InstancesTimelineFComponent as FunctionalComponent<{route: RouteLocationNormalized}, EmitsOptions>,
            },
            props: {
              default: true,
              SideBarInnerRouteView: (route: RouteLocationNormalized) => ({ route }),
            },
          },
        ],
      },
      {
        path: 'profile',
        alias: 'profile/sections',
        components: {
          Action: BasePlaceHolderView,
          SideBar: BasePlaceHolderView,
        },
        children: [
          {
            path: '',
            name: 'client.profile',
            props: true,
            component: BodyMapTimelineWrapper,
            meta: { isAngular: true },
          },
          {
            path: 'gp_connect/:section?',
            name: 'gpConnect',
            component: GpConnectRightPanelMain,
            props: true,
          },

          // TODO this route is  temporary until we implement the rest of profile sub routes
          {
            path: ':subSection',
            alias: '/clients/:clientId/profile/:subSection',
            name: 'client.profile.subSections',
            component: BasePlaceHolderView,
            props: false,
            meta: { isAngular: true },
          },
        ],
      },
      {
        path: 'profile/sections/organisation_state',
        components: {
          Action: async () => componentBaseOnFT('transferClients', TransferClientsPlusButton),
          SideBar: BasePlaceHolderView,
        },
        children: [
          {
            path: 'transfer',
            name: 'client.profile.transfer',
            component: TransferClientsForm,
            props: (route: RouteLocationNormalized) => ({ clientId: +route.params.clientId }),
          },
          {
            path: '',
            name: 'client.profile.organisationState',
            component: BasePlaceHolderView,
          },
        ],
      },
      {
        path: 'records',
        components: {
          Action: BasePlaceHolderView,
        },
        children: [
          {
            path: '',
            name: 'client.medicalRecords',
            component: MedicalRecords,
            props: (route: RouteLocationNormalized) => ({ serviceId: route.query.serviceId, clientId: route.params.clientId }),
          },
          {
            path: '/clients/:clientId/records/london-care-record',
            name: 'client.medicalRecords.londonCareRecord',
            component: LondonCareRecordMainPanelVue,
            props: (route: RouteLocationNormalized) => ({ serviceId: route.query.serviceId, clientId: route.params.clientId }),
          },
        ],
      },
      {
        path: 'dashboard',
        components: {
          Action: BasePlaceHolderView,
          SideBar: BasePlaceHolderView,
        },
        children: [
          {
            path: '',
            name: 'client.dashboard',
            component: BasePlaceHolderView,
            meta: { isAngular: true },
          },
        ],
      },
      {
        path: 'assessments',
        // alias: ['assessments/interactions', 'assessments/assessments'],
        components: {
          Action: InteractionPlusButton,
          SideBar: AssessmentsLeftPanel,
        },
        beforeEnter: (to: RouteLocationNormalized) => {
          useServiceFilteringStore().$reset(+to.params.clientId, true);
          useAssessmentStore().$reset(+to.params.clientId);
        },
        children: [
          {
            path: '',
            name: 'client.assessments',
            component: BodyMapTimelineWrapper,
            props: true,
            meta: { isAngular: true },
          },
          {
            path: '/clients/:clientId(\\d+)/assessments/interactions/:id(\\d+)',
            name: 'client.assessments.interaction',
            component: InteractionsRightPanelMain,
            props: true,
          },
          {
            path: '/clients/:clientId(\\d+)/assessments/interactions/new',
            name: 'client.assessments.interaction_new',
            component: InteractionsRightPanelMain,
            props: (route: RouteLocationNormalized) => ({ serviceId: route.query.serviceId, clientId: route.params.clientId }),
          },
          {
            path: '/clients/:clientId(\\d+)/assessments/assessments/assessments_selector',
            name: 'client.assessments.assessment_selector',
            component: AssessmentsSelector,
            props: false,
          },
          {
            // TODO this route is  temporary until we implement the rest of assessments sub routes
            path: ':pathMatch(.*)*',
            name: 'client.assessments.subSection',
            component: BasePlaceHolderView,
            meta: { isAngular: true },
          },
        ],
        props: true,
      },
      {
        path: 'events',
        beforeEnter: async () => {
          await loadModuleLanguageFileAsync('events');
          await loadModuleLanguageFileAsync('timeline');
        },
        components: {
          Action: EventsPlusButtonWrapper,
          SideBar: InteractionsLeftPanel,
        },
        children: [
          {
            path: '',
            name: 'client.events',
            components: {
              default: BasePlaceHolderView,
              SideBarInnerRouteView: EventsTimeline,
            },
            props: true,
          },
          {
            path: ':instanceId(\\d+)',
            name: 'client.events.instance',
            components:
              {
                default: BasePlaceHolderView,
                SideBarInnerRouteView: EventViewTimeline,
              },
            props: true,
            beforeEnter: (to: RouteLocationNormalized) => {
              instancesStore.asyncById(+to.params.clientId);
            },
          },
          {
            path: 'service_selector',
            name: 'client.events.service_selector',
            components: {
              default: EventSelector,
              SideBarInnerRouteView: InstancesTimelineFComponent as FunctionalComponent<{route: RouteLocationNormalized}, EmitsOptions>,
            },
            props: {
              default: true,
              SideBarInnerRouteView: (route: RouteLocationNormalized) => ({ route }),
            },
          },
          {
            path: 'new',
            name: 'client.events.new',
            component: InteractionsRightPanelMain,
            props: (route) => ({ serviceId: route.query.serviceId, clientId: route.params.clientId }),
          },
        ],
        props: true,
      },
      {
        path: 'support_network',
        components: {
          Action: BasePlaceHolderView,
          SideBar: BasePlaceHolderView,
        },
        children: [
          {
            path: '',
            name: 'client.supportNetwork',
            component: BasePlaceHolderView,
          },
          {
            path: ':relation_id',
            name: 'client.supportNetwork.edit',
            component: BasePlaceHolderView,
          },
        ],
        props: false,
        meta: { isAngular: true },
      },
      {
        path: 'careplan',
        beforeEnter: async () => {
          await loadModuleLanguageFileAsync('careplan');
        },
        components: {
          Action: BasePlaceHolderView,
          SideBar: BasePlaceHolderView,
        },
        children: [
          {
            path: '',
            name: 'client.careplan',
            component: BasePlaceHolderView,
          },
          {
            path: 'client_categories/:client_category_id/client_subcategories/:client_subcategory_id',
            name: 'client.careplan.clientCategory.clientSubcategory',
            component: ComponentsWrapper,
            props: (route: RouteLocationNormalized) => ({ clientId: +route.params.clientId }),
            meta: { isAngular: true },
          },
          {
            path: 'consent_signature/:section?',
            name: 'client.careplan.consent_signature',
            component: CarePlanConsentRightPanel,
            props: true,
          },
          {
            // TODO this route is  temporary until we implement the rest of careplan sub routes
            path: ':pathMatch(.*)*',
            name: 'client.careplan.subSection',
            component: BasePlaceHolderView,
          },
        ],
        props: false,
        meta: { isAngular: true },
      },
      {
        path: 'alarms',
        components: {
          Action: BasePlaceHolderView,
          SideBar: BasePlaceHolderView,
        },
        children: [
          {
            path: '',
            name: 'client.alarms',
            component: BasePlaceHolderView,
          },
          {
            path: ':alarm_id',
            name: 'alarm',
            component: BasePlaceHolderView,
          },
        ],
        props: false,
        meta: { isAngular: true },
      },
    ],
    props: {
      TopBar: (route: RouteLocationNormalized) => ({ clientId: +route.params.clientId }),
    },
  },
];

export const allowedClientRoutes = (authContext: RouteAuthContext) => {
  const allowedRoutes: string[] = [];
  if (authContext.userHasPermission('readCareplan')) {
    allowedRoutes.push(
      'client.careplan',
      'client.careplan.clientCategory.clientSubcategory',
      // temporary
      'client.careplan.subSection',
      // todo the previous state is temporary until we implement the bellow careplan sub states
      'client.careplan.print',
      'clientCategory',
      'client.clientCategory',
      'clientSubcategory',
      'client.clientSubcategory',
    );
    if (authContext.getFeatureToggle('webConsentSignatures')) {
      allowedRoutes.push(
        'client.careplan.consent_signature',
        'client.careplan.consent_signature.latest_consent',
        'client.careplan.consent_signature.update_consent',
        'client.careplan.consent_signature.agreement_history',
      );
    }
  }

  // TODO handle authorization in GpConnect component or destructure into individual routes???
  if (authContext.userCan('full', 'gpConnect')) {
    allowedRoutes.push(
      'client.profile.gpConnect',
      'client.profile.gpConnect.summary',
      'client.profile.gpConnect.encounters',
      'client.profile.gpConnect.clinical',
      'client.profile.gpConnect.problems',
      'client.profile.gpConnect.allergies',
      'client.profile.gpConnect.medications',
      'client.profile.gpConnect.referrals',
      'client.profile.gpConnect.observations',
      'client.profile.gpConnect.immunisations',
      'client.profile.gpConnect.admin',
    );
  } else if (authContext.userCan('nonClinical', 'gpConnect')) {
    allowedRoutes.push(
      'client.profile.gpConnect',
      'client.profile.gpConnect.summary',
      'client.profile.gpConnect.allergies',
      'client.profile.gpConnect.medications',
      'client.profile.gpConnect.immunisations',
    );
  }

  // London care record permissions
  if (authContext.userCan('registeredManager', 'londonCareRecord')
    || authContext.userCan('seniorCarer', 'londonCareRecord')
    || authContext.userCan('admin', 'londonCareRecord')) {
    allowedRoutes.push(
      'client.medicalRecords.londonCareRecord',
    );
  }

  if (authContext.currentUser.carer) {
    allowedRoutes.push(
      'clientList',
      'client',
      'client.dashboard',
      'client.events',
      'client.events.instance',
      'client.events.interaction',
      'client.events.new',
      'client.events.service_selector',
      'client.timeline',
      'client.timeline.body_map',
      'client.timeline.body_map.instance',
      'client.timeline.service_selector',
      'client.timeline.interaction_new',
      'client.timeline.interaction',
      'client.timeline.needinteraction',
      'client.medicalRecords',
      'client.supportNetwork',
      'client.supportNetwork.edit',
      'client.profile',
      // temporary
      'client.profile.subSections',
      // todo the previous state is temporary until we implement the bellow profile sub states
      'client.profile.generalInfo',
      'client.profile.address',
      'client.profile.biography',
      'client.profile.organisationState',
      'client.profile.additionalInfo',
      'client.profile.documents',
      'client.profile.printAbstract',
      'client.profile.print',
      // --
      'client.hospital_passport_print',
      'client.alarms',
      'alarm',
      // Todo check could not find in angular routes
      'alarms',
      //  should not be in client routes
      'clientsAlarmList',
    );
  }

  if (authContext.userCan('create', 'Client')) {
    allowedRoutes.push('clientList.new');
  }

  if (authContext.getFeatureToggle('assessments')) {
    allowedRoutes.push(
      'client.assessments',
      'client.assessments.assessment_selector',
      // temporary
      'client.assessments.subSection',
      // todo the previous state is temporary until we implement the bellow assessments sub routes
      'client.assessments.interaction_new',
      'client.assessments.interaction_new.documents',
      'client.assessments.interaction',
      'client.assessments.interaction.documents',
      'client.assessments.interaction.cancel',
    );
  }

  if (authContext.getFeatureToggle('transferClients')) {
    if (authContext.currentUser.carer) {
      allowedRoutes.push(
        'client.profile.transfer',
      );
    }
  }

  return allowedRoutes;
};
