import {
  add,
  addDays,
  addWeeks,
  compareAsc,
  format,
  isAfter,
  isToday,
  isYesterday,
  startOfDay, formatDistanceStrict,
} from 'date-fns';
import * as Locales from 'date-fns/locale';
import use from '@/_shared/compositionApi';

const { translate } = use.helpers();

const dateFormat = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

const timeFormat = new Intl.DateTimeFormat('en', {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
});

export const ensureDate = (dateTimeOrString: Date | string = new Date()) => (typeof dateTimeOrString === 'string' ? new Date(dateTimeOrString) : dateTimeOrString);

export const formatDate = (dateTimeOrString: Date | string) => {
  const d = dateFormat.formatToParts(ensureDate(dateTimeOrString));
  return `${d[2].value}/${d[0].value}/${d[4].value}`;
};

export const formatAmPmDateTime = (dateTimeOrString: Date | string) => {
  const dateObj = new Date(ensureDate(dateTimeOrString));
  const shortTime = new Intl.DateTimeFormat('en-GB', {
    hour12: true,
    hour: 'numeric',
    minute: '2-digit',
  });
  return `${shortTime.format(dateObj).toUpperCase()} - ${dateObj.toLocaleDateString('en-GB')}`;
};

export const formatISODate = (dateTimeOrString: Date | string) => {
  const d = dateFormat.formatToParts(ensureDate(dateTimeOrString));
  return `${d[4].value}-${d[0].value}-${d[2].value}`;
};

export const formatTime = (dateTimeOrString: Date | string) => {
  const d = ensureDate(dateTimeOrString);
  return timeFormat.format(d);
};

export const formatTimezoneDate = (dateTimeOrString: Date | string) => {
  const date = new Date(dateTimeOrString);
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  return `${yyyy}-${mm < 10 ? '0' : ''}${mm}-${dd < 10 ? '0' : ''}${dd}`;
};

export const formatFriendlyDate = (dateTimeOrString: Date | string) => {
  const date = ensureDate(dateTimeOrString);
  if (isYesterday(date)) {
    return translate('common.yesterday');
  }
  if (isToday(date)) {
    return translate('common.today');
  }
  let formatString = 'dd MMM'; // dd MMM = date and month eg "01 Jun"
  if (isAfter(addWeeks(date, 1), new Date())) {
    formatString = 'EEEE'; // EEEE = Weekday eg "Monday"
  }
  return format(date, formatString);
};

export const formatFriendlyDayOfTheWeek = (dateTimeOrString: Date | string) => {
  const date = ensureDate(dateTimeOrString);
  return format(date, 'dd MMM');
};

export const formatFriendlyDateTime = (dateTimeOrString: Date | string) => `${formatFriendlyDate(dateTimeOrString)} ${format(ensureDate(dateTimeOrString), 'HH:mm')}`;

export const formatDateTime = (dateTimeOrString: Date | string) => `${formatDate(dateTimeOrString)} - ${formatTime(dateTimeOrString)}`;
export const compareDateAsc = (a: string|null|undefined, b: string|null|undefined) => {
  if (a == null && b == null) {
    return 0;
  }
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }
  return compareAsc(new Date(a as string), new Date(b as string));
};

export const checkIfDateGreaterThanDays = (dateToCheck: Date, daysToAdd: number) => {
  const currentDate = new Date();
  const futureDate = addDays(currentDate, daysToAdd);
  return isAfter(dateToCheck, futureDate);
};

export const monthsSinceDate = (dateTimeOrString: Date | string) => {
  const date = ensureDate(dateTimeOrString);
  const today = new Date();
  return monthDiff(date, today);
};

function monthDiff(dateFrom: Date, dateTo: Date) {
  return dateTo.getMonth() - dateFrom.getMonth()
    + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));
}

export const formatToDate = (date: string) => new Date(date.split('/').reverse().join('-'));

export const getUpdatedAgoText = (date: Date) => {
  const locale = Object.values(Locales).find((loc) => loc.code === window.currentPerson?.language);
  const updatedAgo = formatDistanceStrict(date, new Date(), { addSuffix: true, locale });
  return updatedAgo;
};

export const formatInteractionDate = (dateString: string) => {
  const fromDate = startOfDay(new Date());
  const today = fromDate;
  const tomorrow = add(fromDate, { days: 1 });
  const date = new Date(dateString);
  if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
    return 'Today';
  }
  if (date.getDate() === tomorrow.getDate() && date.getMonth() === tomorrow.getMonth() && date.getFullYear() === today.getFullYear()) {
    return 'Tomorrow';
  }
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: '2-digit',
  });
};
