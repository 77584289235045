import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import { ref } from 'vue';
import useEventListener from '@/_shared/Composables/event';
import { Need } from '@/timeline/types/Need';
import CarePlanReviewsWrapper from '@/timeline/components/CarePlanReview/CarePlanReviewsWrapper.vue';
import ServiceLoggers from './ServiceLoggers.vue';
import CarePlanBodyMap from './CarePlanBodyMap.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComponentsWrapper',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
},
  setup(__props) {



const loggerEvent = ref<CustomEvent | null>(null);
const bodyMapEvent = ref<CustomEvent | null>(null);
const carePlanReviewEvent = ref<CustomEvent<Need[]> | null>(null);

const setCustomEvent = (event: CustomEvent) => {
  setTimeout(async () => {
    if (event.type === 'ServiceLoggerInit') loggerEvent.value = event;
    if (event.type === 'BodyMapInit') bodyMapEvent.value = event;
    if (event.type === 'CarePlanReviewInit') carePlanReviewEvent.value = event;
  }, 500);
};

const resetEvent = (event: CustomEvent) => {
  if (event.type === 'BodyMapUnmount') bodyMapEvent.value = null;
};

useEventListener(window, 'ServiceLoggerInit', setCustomEvent);
useEventListener(window, 'BodyMapInit', setCustomEvent);
useEventListener(window, 'BodyMapUnmount', resetEvent);
useEventListener(window, 'CarePlanReviewInit', setCustomEvent);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (loggerEvent.value)
      ? (_openBlock(), _createBlock(ServiceLoggers, {
          key: 0,
          "client-id": __props.clientId,
          "logger-event": loggerEvent.value
        }, null, 8, ["client-id", "logger-event"]))
      : _createCommentVNode("", true),
    (bodyMapEvent.value)
      ? (_openBlock(), _createBlock(CarePlanBodyMap, {
          key: 1,
          "client-id": __props.clientId,
          "body-map-event": bodyMapEvent.value
        }, null, 8, ["client-id", "body-map-event"]))
      : _createCommentVNode("", true),
    (carePlanReviewEvent.value)
      ? (_openBlock(), _createBlock(CarePlanReviewsWrapper, {
          key: 2,
          "needs-event": carePlanReviewEvent.value.detail
        }, null, 8, ["needs-event"]))
      : _createCommentVNode("", true)
  ]))
}
}

})