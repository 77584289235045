import apiClient from '@/_shared/services/apiClient';
import {
  ClientCagegory,
  ClientSubcategory,
  Need,
  NeedReview,
} from '@/timeline/types/Need';
import { ProvidedServiceQueryResponse } from '../store/providedServices';
import { ProvidedService } from '../types/providedService';

export interface NeedsResponse {
    need: Need;
}

export interface ClientSubcategoryResponse {
    clientSubcategory: ClientSubcategory;
}

export interface ClientCategoryResponse {
    clientCategories: ClientCagegory[];
}

export interface NeedsQueryParams {
    id: number;
    lastReviewId: number | null;
}

export const fetchNeed = async (params: NeedsQueryParams): Promise<Need> => {
  let url = `/api/v2/needs/${params.id}?extended=true`;
  if (params.lastReviewId) {
    url += `&last_review_id=${params.lastReviewId}`;
  }
  return (await apiClient.get<NeedsResponse>(url)).need;
};

export const fetchNeedProvidedServices = async (aNeed: Need): Promise<ProvidedService[]> => {
  const providedServiceIds = aNeed.needProvidedServices.map((p) => p.providedServiceId);
  const url = `/api/v2/organisation_units/${aNeed.organisationUnitId}/provided_services/for_need`;
  const response = await apiClient.post<ProvidedServiceQueryResponse>(url, {
    query: {
      enforced_ids: providedServiceIds,
    },
    clientId: aNeed.clientId,
  });

  return response.providedServices;
};

export const fetchAllNeedReviews = async (id: number): Promise<NeedReview[]> => {
  const url = `/api/v2/needs/${id}?only_reviews=true`;
  const response = (await apiClient.get<NeedsResponse>(url)).need;
  return response.reviews;
};

export const generateCareplanPath = async (id: number, clientId: number, includeNeeds = true): Promise<string> => {
  const url = `/api/v2/client_subcategories/${id}`;
  const response = (await (apiClient.get<ClientSubcategoryResponse>(url, { params: { includeNeeds } }))).clientSubcategory;
  return `/clients/${clientId}/careplan/client_categories/${response.clientCategoryId}/client_subcategories/${id}`;
};

export const getClientCategories = async (clientId: number): Promise<ClientCategoryResponse> => {
  const url = `/api/v2/clients/${clientId}/client_categories?archived=false&include_subcategories_summary=true`;
  return apiClient.get<ClientCategoryResponse>(url);
};

export const saveNeed = async (aNeed: Need): Promise<Need> => {
  const url = `/api/v2/needs/${aNeed.id}?extended=true`;
  return (await (apiClient.put<NeedsResponse>(url, { need: aNeed }))).need;
};
