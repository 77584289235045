import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-td"
}

import { PropType } from 'vue';
import { ListTableRow } from '@/_shared/components/display/BaseListTable/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseListTableRow',
  props: {
  row: {
    type: Object as PropType<ListTableRow>,
    required: true,
  },
  displayExtraSlotColumn: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass([__props.row.hasOwnProperty('status') && `status-${__props.row.status}`, "v-tr"])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.row.cells, (cellData, key) => {
      return (_openBlock(), _createElementBlock("td", {
        class: "v-td",
        key: `cell-${key}`
      }, [
        _renderSlot(_ctx.$slots, `slot-${key}`, { data: cellData }, () => [
          _createTextVNode(_toDisplayString(cellData), 1)
        ])
      ]))
    }), 128)),
    (__props.displayExtraSlotColumn)
      ? (_openBlock(), _createElementBlock("td", _hoisted_1, [
          _renderSlot(_ctx.$slots, "actions", { row: __props.row })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})