import use from '@/_shared/compositionApi';
import { InstanceState, NourishInstance } from '../types/NourishInstance';

export type GroupedInstancesByState<T extends NourishInstance = NourishInstance> = {
  state: InstanceState;
  label: string;
  instances: T[];
}[];

const useInstancesHelper = () => {
  const { translate } = use.helpers();

  const sortInstancesByStateTime = (a: NourishInstance, b: NourishInstance) => (a.stateTime < b.stateTime ? 1 : -1);

  const sortByMissedInteractions = (a: NourishInstance, b: NourishInstance) => {
    if (!a?.missedInteractions?.length && !b?.missedInteractions?.length) {
      return sortInstancesByStateTime(a, b);
    }
    if (!a?.missedInteractions?.length) {
      return 1;
    }
    if (!b?.missedInteractions?.length) {
      return -1;
    }
    return (a.missedInteractions[0]?.finishAt ?? 0) < (b.missedInteractions[0]?.finishAt ?? 0) ? -1 : 1;
  };

  const updateMissedInteractions = <T extends NourishInstance = NourishInstance>(instances: T[]) => instances.map((instance) => {
    const missedInteractions = instance.interactions?.filter(
      (interaction) => interaction.state === 'planned' && interaction.finishAt && new Date(interaction.finishAt).getTime() < new Date().getTime(),
    ) || undefined;
    missedInteractions?.sort((a, b) => {
      if (a.finishAt === null || b.finishAt === null) return 0;
      return a.finishAt < b.finishAt ? -1 : 1;
    });
    return {
      ...instance,
      missedInteractions,
    };
  });

  const groupInstancesByState = <T extends NourishInstance = NourishInstance>(
    instances: T[],
    translationKey?: string,
  ) => {
    const groups: GroupedInstancesByState<T> = [];
    instances.forEach((instance) => {
      const group = groups.find((g) => g.state === instance.state);
      if (group) {
        group.instances.push(instance);
      } else {
        groups.push({
          state: instance.state,
          label: translationKey ? translate(`${translationKey}.${instance.state}`) : instance.state,
          instances: [instance],
        });
      }
    });

    // Sort groups by StateTime.
    return groups.map((group) => {
      // Sort active instances by missed interactions.
      if (group.state === 'active') {
        const updatedActiveInstances = updateMissedInteractions<T>(group.instances);
        updatedActiveInstances.sort(sortByMissedInteractions);
        return {
          ...group,
          instances: updatedActiveInstances,
        };
      }
      return {
        ...group,
        instances: group.instances.sort(sortInstancesByStateTime),
      };
    });
  };

  const filterGroupedInstances = <T extends NourishInstance = NourishInstance>(
    groupedInstances: GroupedInstancesByState<T>,
    filterFunction: (skinInstance: T) => boolean,
  ) => {
    const instances: GroupedInstancesByState<T> = [];
    groupedInstances.forEach((instanceByGroup) => {
      const stateInstances = instanceByGroup.instances.filter(filterFunction);
      instances.push({ ...instanceByGroup, instances: stateInstances });
    });
    return instances;
  };

  return {
    sortInstancesByStateTime,
    groupInstancesByState,
    filterGroupedInstances,
  };
};

export default useInstancesHelper;
