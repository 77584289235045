import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "v-event-list-entry" }
const _hoisted_2 = { class: "v-event-date-text v-event-date-text-primary" }
const _hoisted_3 = {
  key: 0,
  class: "v-event-date-text v-event-date-text-secondary"
}

import { safeTranslate } from '@/_shared/translations/i18n';
import { computed, PropType } from 'vue';
import { EventPwsInstance } from '@/_shared/types/NourishInstance';
import InstanceListEntry from '@/_shared/components/display/InstanceListEntry.vue';
import { format } from 'date-fns';
import { formatDisplayTime } from '@/timeline/helper/interactionsHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventListEntry',
  props: {
  instance: {
    type: Object as PropType<EventPwsInstance>,
    required: true,
  },
},
  emits: [
  'selectInstance',
],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const additionalHeaders = [
  safeTranslate(`datasets.${props.instance?.metadata.specificType}`),
  safeTranslate(`datasets.${props.instance?.metadata.severity}`),
];

const unknownDate = safeTranslate('common.unknown_date');
const [unknownDateFirstRow, unknownDateSecondRow] = unknownDate.split(' ');

const dateFirstRow = computed(() => (props.instance.metadata?.eventDateTime ? format(new Date(props.instance.metadata.eventDateTime), 'd MMM yy') : unknownDateFirstRow));
const dateSecondRow = computed(() => (props.instance.metadata?.eventDateTime ? formatDisplayTime(props.instance.metadata.eventDateTime) : unknownDateSecondRow));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstanceListEntry, {
      title: _unref(safeTranslate)(`datasets.${__props.instance.subType}`),
      "additional-headers": additionalHeaders,
      reference: __props.instance.reference,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('selectInstance', __props.instance)))
    }, {
      eventDateTime: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(dateFirstRow.value), 1),
        (dateSecondRow.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(dateSecondRow.value), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "reference"])
  ]))
}
}

})