import { safeTranslate } from '@/_shared/translations/i18n';
import type { EventPwsInstance, InstanceState } from '@/_shared/types/NourishInstance';
import type { DropdownFilterOption } from '../types/dropdownFilterOption';

export function instanceFilterOptions(values: string[], translationKey: string) {
  const options = values.map((option) => ({
    name: safeTranslate(`${translationKey}.${option}`),
    codename: option,
  }));

  if (options.length !== 1) {
    options.push({
      name: safeTranslate('filter.all'),
      codename: 'all',
    });
  }

  return options.sort(sortOptionsComparator);
}

export function instanceFilterOptionsWithCount(values: DropdownFilterOption[], translationKey: string) {
  const options = values.map((option) => ({
    name: safeTranslate(`${translationKey}.${option.name}`),
    codename: option.name,
    count: option.count,
  }));
  let allCount = 0;
  options.forEach((option) => {
    allCount += option.count ?? 0;
  });
  if (options.length !== 1) {
    options.push({
      name: safeTranslate('filter.all'),
      codename: 'all',
      count: allCount,
    });
  }

  return options.sort(sortOptionsComparator);
}

function sortOptionsComparator(a: DropdownFilterOption, b: DropdownFilterOption) {
  return a.name.localeCompare(b.name);
}

export function instanceStateTranslations(state: InstanceState, translationKey: string) {
  const translations = {
    new: safeTranslate(`${translationKey}.new`),
    active: safeTranslate(`${translationKey}.active`),
    archived: safeTranslate(`${translationKey}.archived`),
  };

  return translations[state];
}

export const instanceFilterStateOptions = (instanceStates: string[], translationKey: string) => {
  const options: DropdownFilterOption[] = [];
  // Set unique array of states.
  const states = new Set(instanceStates);
  if (states.has('new') && states.has('active')) {
    options.push({
      name: `${instanceStateTranslations('new', translationKey)} & ${instanceStateTranslations('active', translationKey)}`,
      codename: 'new&active',
    });
  }
  if (states.has('new') && !states.has('active')) {
    options.push({
      name: instanceStateTranslations('new', translationKey),
      codename: 'new',
    });
  }
  if (!states.has('new') && states.has('active')) {
    options.push({
      name: instanceStateTranslations('active', translationKey),
      codename: 'active',
    });
  }
  if (states.has('archived')) {
    options.push({
      name: instanceStateTranslations('archived', translationKey),
      codename: 'archived',
    });
  }
  if (options.length !== 1) {
    options.push({
      name: safeTranslate('filter.all'),
      codename: 'all',
    });
  }

  return options;
};

export function filterEventPWSInstances(currentSeverity: string, currentState: string, instance: EventPwsInstance) {
  const matchesSeverity = currentSeverity === 'all' || instance.metadata.severity === currentSeverity;
  const matchesState = currentState === 'all'
    || (currentState === 'new&active' ? instance.state !== 'archived' : instance.state === currentState);
  return matchesSeverity && matchesState;
}

export function filterTextToDisplay(filters: string[]) {
  const filtersToDisplay: string[] = [];
  filters.forEach((f) => {
    if (f && !filtersToDisplay.includes(f)) {
      filtersToDisplay.push(f);
    }
  });
  return filtersToDisplay.join(', ');
}
