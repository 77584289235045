import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-last-closed-review" }
const _hoisted_2 = { class: "v-last-closed-review-title" }
const _hoisted_3 = { class: "v-last-closed-review-content" }
const _hoisted_4 = { class: "v-last-closed-review-content-datetime" }
const _hoisted_5 = { class: "v-last-closed-review-content-datetime-text" }
const _hoisted_6 = { class: "v-last-closed-review-content-datetime-text" }
const _hoisted_7 = { class: "v-last-closed-review-content-text" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "v-missing-text"
}

import { computed, PropType } from 'vue';
import use from '@/_shared/compositionApi';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { NeedReview } from '../../types/Need';
import CarePlanPerson from './CarePlanPerson.vue';
import { formatDate, formatTime, getResponsiblePeople } from './utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'LastClosedReview',
  props: {
  review: {
    type: Object as PropType<NeedReview>,
    required: true,
  },
},
  setup(__props) {

const { translate } = use.helpers();

const props = __props;

const lastReviewDate = computed(() => formatDate(props.review.finishAt));

const lastReviewTime = computed(() => formatTime(props.review.finishAt));

const responsiblePeople = getResponsiblePeople(props.review.responsiblePersonIds);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(translate)('need.review_note')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createVNode(BaseIcon, {
            name: "nr-icon-calendar",
            size: "sm",
            compact: "",
            noHover: ""
          }),
          _createElementVNode("span", _hoisted_5, _toDisplayString(lastReviewDate.value), 1)
        ]),
        _createElementVNode("div", null, [
          _createVNode(BaseIcon, {
            name: "nr-icon-watch",
            size: "sm",
            compact: "",
            noHover: ""
          }),
          _createElementVNode("span", _hoisted_6, _toDisplayString(lastReviewTime.value), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", {
          class: "v-preserve-linebreaks",
          innerHTML: __props.review.notePublic
        }, null, 8, _hoisted_8),
        (!__props.review.notePublic)
          ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_unref(translate)('need.review_note_empty')), 1))
          : _createCommentVNode("", true)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(responsiblePeople), (person) => {
        return (_openBlock(), _createBlock(CarePlanPerson, {
          key: person.id,
          person: person
        }, null, 8, ["person"]))
      }), 128))
    ])
  ]))
}
}

})