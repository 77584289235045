import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-left-col"
}
const _hoisted_2 = { class: "v-main-col" }
const _hoisted_3 = {
  key: 1,
  class: "v-right-col"
}
const _hoisted_4 = {
  key: 0,
  class: "v-base-list-entry-extended"
}
const _hoisted_5 = {
  key: 0,
  class: "v-left-col-extended"
}
const _hoisted_6 = {
  key: 1,
  class: "v-main-col-extended"
}
const _hoisted_7 = {
  key: 2,
  class: "v-right-col-extended"
}
const _hoisted_8 = {
  key: 1,
  class: "children-wrapper"
}

import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import type { PropType } from 'vue';
import { NestedLink } from '@/_shared/types/baseListEntry';
import { IconColor } from '@/_shared/types/iconColor';
import { IconSize } from '@/_shared/types/iconSize';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseListEntry',
  props: {
  title: {
    type: String,
  },
  icon: {
    type: String,
    default: '',
  },
  backgroundIconColour: {
    type: String as PropType<IconColor>,
  },
  rightIcon: {
    type: String,
  },
  rightIconColor: {
    type: String as PropType<IconColor>,
  },
  rightIconSize: {
    type: String as PropType<IconSize>,
    default: 'lg',
  },
  event: {
    type: Function,
  },
  active: {
    type: Boolean,
  },
  fontSize: {
    type: String,
    default: '20px',
  },
  blankIcon: {
    type: Boolean,
    default: false,
  },
  invertedColour: {
    type: Boolean,
    default: false,
  },
  padding: {
    type: String,
  },
  children: {
    type: Object as PropType<NestedLink[]>,
    default: () => [] as NestedLink[],
  },
  withIcon: {
    type: Boolean,
    default: true,
  },
  isChild: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

_useCssVars(_ctx => ({
  "2c422950": (__props.fontSize),
  "26b03b52": (_unref(paddingSize))
}))

const props = __props;

// TODO remove iconSize once we migrate to material design and all icons are same format
const iconSize = () => {
  if (!props.icon) {
    return '';
  }

  return props.icon.includes('ncp') ? '25px' : '20px';
};

const paddingSize = props.padding || `${Number(props.fontSize.split('px')[0]) - 2}px`;

const rightIconColour = () => {
  if (props.rightIconColor) {
    return props.rightIconColor;
  }

  if (!isExpandingIcon()) {
    return props.invertedColour ? 'white' : 'black';
  }

  if (props.invertedColour) {
    if (props.active) { return 'teal'; }
    return props.isChild ? 'transparent' : 'white';
  }
  return props.active ? 'black' : 'grey';
};

const isExpandingIcon = () => props.rightIcon?.includes('chevron') || props.rightIcon?.includes('plus') || props.rightIcon?.includes('minus');

const backgroundIconColour = () => {
  if (props.backgroundIconColour) {
    return props.backgroundIconColour;
  }

  if (props.blankIcon) {
    return undefined;
  }
  return props.invertedColour ? 'transparent' : 'black';
};

const foregroundIconColour = () => {
  if (props.blankIcon) {
    return undefined;
  }
  if (props.invertedColour) {
    return props.active ? 'teal' : 'white';
  }
  return 'white';
};

return (_ctx: any,_cache: any) => {
  const _component_base_list_entry = _resolveComponent("base-list-entry", true)!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-list-entry", { active: __props.active, 'v-list-entry-inverted': __props.invertedColour }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (__props.event || (() => {})))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["v-base-list-entry-inner", { active: __props.active, 'v-base-list-entry-inner-inverted': __props.invertedColour }])
    }, [
      (__props.withIcon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "customIcon"),
            (!_ctx.$slots.customIcon)
              ? (_openBlock(), _createBlock(BaseIcon, {
                  key: 0,
                  name: __props.icon,
                  fontSize: iconSize(),
                  noHover: "",
                  compact: "",
                  width: "36px",
                  height: "36px",
                  transparent: props.backgroundIconColour === 'transparent',
                  backgroundColor: backgroundIconColour(),
                  foregroundColor: foregroundIconColour(),
                  foregroundHoverColor: __props.blankIcon ? undefined : 'white'
                }, null, 8, ["name", "fontSize", "transparent", "backgroundColor", "foregroundColor", "foregroundHoverColor"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "personBadges"),
        _createElementVNode("h2", {
          class: _normalizeClass(["v-main-title v-primary-title", { active: __props.active }])
        }, _toDisplayString(__props.title), 3),
        _renderSlot(_ctx.$slots, "headerMainCol")
      ]),
      (__props.rightIcon || _ctx.$slots.headerRightCol)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (__props.rightIcon)
              ? (_openBlock(), _createBlock(BaseIcon, {
                  key: 0,
                  name: __props.rightIcon,
                  size: __props.rightIconSize,
                  fontSize: "18px",
                  noHover: "",
                  compact: "",
                  foregroundColor: rightIconColour(),
                  foregroundHoverColor: "grey"
                }, null, 8, ["name", "size", "foregroundColor"]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "headerRightCol")
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.$slots.extendedLeftCol || _ctx.$slots.extendedMainCol || _ctx.$slots.extendedRightCol)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("hr", { class: "v-interaction-list-entry-divider" }, null, -1)),
          (_ctx.$slots.extendedLeftCol)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "extendedLeftCol")
              ]))
            : _createCommentVNode("", true),
          (_ctx.$slots.extendedMainCol)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "extendedMainCol")
              ]))
            : _createCommentVNode("", true),
          (_ctx.$slots.extendedRightCol)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _renderSlot(_ctx.$slots, "extendedRightCol")
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (__props.children?.length && __props.active)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.children, (childEntry, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: childEntry?.id || index
            }, [
              _renderSlot(_ctx.$slots, "childEntry", _mergeProps({ ref_for: true }, childEntry), () => [
                _createVNode(_component_base_list_entry, _mergeProps({ ref_for: true }, childEntry.props), null, 16)
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})