import {
  h, PropType, VNode,
  Component, VNodeProps,
} from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import BodyMapViewTimeline from '@/timeline/components/BodyMap/BodyMapViewTimeline.vue';
import BodyMapTimeline from '@/timeline/components/BodyMap/BodyMapTimeline.vue';
import EventViewTimeline from '@/events/components/EventViewTimeline.vue';
import EventsTimeline from '@/events/components/EventsTimeline.vue';

export type InstancesTimelineProps = VNodeProps & {
  route: RouteLocationNormalized,
}

export const instancesFCMap = {
  instances: {
    main: BodyMapTimeline,
    view: BodyMapViewTimeline,
  },
  events: {
    main: EventsTimeline,
    view: EventViewTimeline,
  },
};

function InstancesTimelineFComponent(props: InstancesTimelineProps): VNode | null {
  const matchedEntry = Object.entries(instancesFCMap).find(([key]) => props.route.path.includes(key));

  if (matchedEntry) {
    const [, components] = matchedEntry;
    const { main, view } = components;
    if (props.route.params.instanceId) {
      return h(view as Component, props.route.params);
    }
    return h(main as Component, {
      ...props.route.params,
    });
  }

  return null;
}

InstancesTimelineFComponent.props = {
  route: {
    type: Object as PropType<RouteLocationNormalized>,
    required: true,
  },
};
export default InstancesTimelineFComponent;
