import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ClientCagegory, Need } from '@/timeline/types/Need';
import {
  fetchAllNeedReviews,
  fetchNeed,
  fetchNeedProvidedServices,
  NeedsQueryParams,
  generateCareplanPath,
  getClientCategories,
  saveNeed,
} from '../services/needApi';
import { ProvidedService } from '../types/providedService';

interface NeedProvidedServiceStore {
  [needId: number]: {
    [lastReviewId: number]: ProvidedService[]
  }
}

const useNeedsStore = defineStore('needs', () => {
  const need = ref<Record<number, Need>>({});
  const needProvidedServices = ref<NeedProvidedServiceStore>({});
  const needVersion = ref<Need>();
  const categories = ref<ClientCagegory[]>([]);

  const getNeedById = (id: number): Need => need.value[id];

  const getProvidedServicesForNeed = (aNeed: Need, id?: number) => {
    const value = needProvidedServices.value[aNeed.id]?.[id || aNeed.lastReviewId || 0];
    if (!value) {
      fetchProvidedServices(aNeed, id);
    }

    return value;
  };

  const getCategories = async (clientId: number) => {
    if (!categories.value.length) {
      categories.value = (await getClientCategories(clientId)).clientCategories;
    }

    return categories.value;
  };

  const fetch = async (params: NeedsQueryParams) => {
    const response = await fetchNeed(params);
    need.value = { ...need.value, [response.id]: response };
    await fetchProvidedServices(response);
  };

  const fetchVersion = async (params: NeedsQueryParams) => {
    needVersion.value = await fetchNeed(params);
    await fetchProvidedServices(needVersion.value);
  };

  const fetchProvidedServices = async (aNeed: Need, id?: number) => {
    const providedServices = await fetchNeedProvidedServices(aNeed);

    const lastReviewId = id || aNeed.lastReviewId || 0;
    needProvidedServices.value[aNeed.id] = { ...needProvidedServices.value[aNeed.id] || {}, [lastReviewId]: providedServices };
  };

  const fetchAllReviews = async (id: number) => {
    const result = await fetchAllNeedReviews(id);
    if (need.value[id]) {
      need.value[id].reviews = result;
    }
    return result;
  };

  const $reset = () => {
    need.value = {};
    needProvidedServices.value = {};
  };

  const save = async (aNeed: Need) => {
    const response = await saveNeed(aNeed);
    need.value = { ...need.value, [response.id]: { ...response } };

    // This is to update the angular part
    const event = new CustomEvent('CarePlanReviewInit', {
      detail: [response],
    });

    window.dispatchEvent(event);
    return response;
  };

  return {
    fetch,
    fetchAllReviews,
    fetchProvidedServices,
    fetchVersion,
    generateCareplanPath,
    getNeedById,
    getProvidedServicesForNeed,
    need,
    needProvidedServices,
    needVersion,
    $reset,
    save,
    getCategories,
  };
});

export default useNeedsStore;
