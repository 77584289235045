import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import BaseRightPanel from '@/_shared/components/general/BaseRightPanel.vue';
import BaseSearchableList from '@/_shared/components/general/BaseSearchableList.vue';
import useServiceFiltering from '@/_shared/store/serviceFiltering';
import { computed } from 'vue';
import { SelectorSection, SelectorItem } from '@/_shared/types/baseSearchableList';
import use from '@/_shared/compositionApi';
import useInstanceRoutes from '@/_shared/router/helpers/useInstanceRoutes';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionSelector',
  setup(__props) {


const route = useRoute();
const router = useRouter();
const { translate } = use.helpers();
const serviceFilteringStore = useServiceFiltering();
const { filteredNonAdhocProvidedServices } = storeToRefs(serviceFilteringStore);
const { alphanumericSort } = serviceFilteringStore;

const { bodyMapMounted } = storeToRefs(serviceFilteringStore);

const sections = computed<SelectorSection[]>(() => {
  let allItems: SelectorItem[] = [];
  let top3: SelectorItem[] = [];
  if (filteredNonAdhocProvidedServices && filteredNonAdhocProvidedServices.value) {
    allItems = filteredNonAdhocProvidedServices.value.map((providedService) => ({
      name: providedService.name,
      callback: () => router.push(newAssessmentLink(providedService.serviceId)),
      key: providedService.id,
    }));

    const sorted = [...filteredNonAdhocProvidedServices.value].sort((a, b) => b.bespokeInteractionsCount - a.bespokeInteractionsCount);
    const top3ProvidedServices = sorted.slice(0, 3).sort(alphanumericSort);
    top3 = bodyMapMounted.value ? [] : top3ProvidedServices.map((providedService) => ({
      name: providedService.name,
      callback: () => router.push(newAssessmentLink(providedService.serviceId)),
      key: providedService.id,
    }));
  }

  return [{
    header: translate('interaction.selector.most_used'),
    searchable: false,
    items: top3,
  },
  {
    header: translate('interaction.selector.all'),
    searchable: true,
    items: allItems,
  }];
});
const { timelineRouteName, currentTimelineViewStartPath } = useInstanceRoutes(route);
const newAssessmentLink = (serviceId: number) => ({
  path: `${currentTimelineViewStartPath.value}/interactions/new`,
  query: { ...route.query, serviceId: +serviceId },
});
const onClose = () => {
  router.push({
    name: timelineRouteName.value,
    query: route.query,
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseRightPanel, null, {
    default: _withCtx(() => [
      _createVNode(BaseSearchableList, {
        title: _unref(translate)('interaction.selector.selector_header'),
        sections: sections.value,
        closeCallback: onClose
      }, null, 8, ["title", "sections"])
    ]),
    _: 1
  }))
}
}

})