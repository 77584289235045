import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "v-risks" }
const _hoisted_2 = { class: "v-risks-title" }
const _hoisted_3 = { class: "v-risks-content" }
const _hoisted_4 = { class: "v-risks-item-subtitle" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "v-risks-item-container" }
const _hoisted_7 = { class: "v-risks-item-score" }
const _hoisted_8 = { class: "v-risks-item-score-title" }
const _hoisted_9 = { class: "v-risks-item-score-entry" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 0,
  class: "v-risks-item-score-entry-description"
}
const _hoisted_12 = { class: "v-risks-item-score" }
const _hoisted_13 = { class: "v-risks-item-score-title" }
const _hoisted_14 = { class: "v-risks-item-score-entry" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = {
  key: 0,
  class: "v-risks-item-score-entry-description"
}
const _hoisted_17 = { class: "v-risks-item-score" }
const _hoisted_18 = { class: "v-risks-item-score-title" }
const _hoisted_19 = { class: "v-risks-item-score-entry" }
const _hoisted_20 = {
  key: 1,
  class: "v-risks-icon v-risks-icon--inactive"
}
const _hoisted_21 = {
  key: 2,
  class: "v-risks-item-score-entry-description"
}
const _hoisted_22 = {
  key: 0,
  class: "v-missing-text"
}

import { PropType } from 'vue';
import use from '@/_shared/compositionApi';
import { RiskAssesment } from '../../types/Need';
import { calculateRiskResultClass, calculateRiskScoreClass } from './utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'RiskAssessment',
  props: {
  risks: {
    type: Array as PropType<RiskAssesment[]>,
    required: true,
  },
},
  setup(__props) {

const { translate } = use.helpers();




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(translate)('need.risks')), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.risks, (risk) => {
        return (_openBlock(), _createElementBlock("div", {
          key: risk.id,
          class: "v-risks-item"
        }, [
          _createElementVNode("h4", _hoisted_4, _toDisplayString(risk.description), 1),
          _createElementVNode("div", {
            class: "v-risks-item-description",
            innerHTML: risk.actions
          }, null, 8, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("h4", _hoisted_8, _toDisplayString(_unref(translate)('need.risk_likelihood')), 1),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", {
                  class: _normalizeClass([`v-risks-icon--${_unref(calculateRiskScoreClass)(risk.likelihood)}`, "v-risks-icon"])
                }, [
                  _createTextVNode(_toDisplayString(risk.likelihood) + " ", 1),
                  (risk.likelihood == null)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, "0"))
                    : _createCommentVNode("", true)
                ], 2),
                (risk.likelihood)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_unref(translate)(`need.risk_likelihood_score_options.${risk.likelihood}`)), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "v-risks-item-spacer" }, " x ", -1)),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("h4", _hoisted_13, _toDisplayString(_unref(translate)('need.risk_impact')), 1),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("span", {
                  class: _normalizeClass(["v-risks-icon", `v-risks-icon--${_unref(calculateRiskScoreClass)(risk.impact)}`])
                }, [
                  _createTextVNode(_toDisplayString(risk.impact) + " ", 1),
                  (risk.impact === null)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, "0"))
                    : _createCommentVNode("", true)
                ], 2),
                (risk.impact)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_unref(translate)(`need.risk_impact_score_options.${risk.impact}`)), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "v-risks-item-spacer" }, " = ", -1)),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("h4", _hoisted_18, _toDisplayString(_unref(translate)('need.risk')), 1),
              _createElementVNode("div", _hoisted_19, [
                (risk.impact !== null && risk.likelihood != null)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass(["v-risks-icon", `v-risks-icon--${_unref(calculateRiskResultClass)(risk.impact * risk.likelihood)}`])
                    }, _toDisplayString(risk.impact * risk.likelihood), 3))
                  : _createCommentVNode("", true),
                (!(risk.impact !== null && risk.likelihood != null))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_20, " 0 "))
                  : _createCommentVNode("", true),
                (risk.impact && risk.likelihood)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_unref(translate)('need.risk_score')), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      }), 128)),
      (__props.risks.length <= 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_22, _toDisplayString(_unref(translate)('common.empty_field_data')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})