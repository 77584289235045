import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BaseCircleButton from '@/_shared/components/buttons/BaseCircleButton.vue';
import { vBaseTooltip } from '@/_shared/directives/';
import use from '@/_shared/compositionApi';
import { useRouter, useRoute } from 'vue-router';
import { computed } from 'vue';
import useUserStore from '@/_shared/store/user';
import hideElementsWithClassesNames from '@/_shared/helpers/useAngularPartHider';
import useInstanceRoutes from '@/_shared/router/helpers/useInstanceRoutes';
import { isMobileView, resizeHandler } from '@/_shared/helpers/mobileViewHelper';
import useEventListener from '@/_shared/Composables/event';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionPlusButton',
  setup(__props) {

const { translate } = use.helpers();
const router = useRouter();
const route = useRoute();
const { isWithinSkinNestedRoutes } = useInstanceRoutes(route);

// Appease vue-mess-detector
const localeRouteName = computed(() => route?.name?.toString() || '');

const targetRouteName = computed(() => {
  switch (true) {
    case localeRouteName.value.includes('assessments'):
      return { name: 'client.assessments.assessment_selector' };
    case isWithinSkinNestedRoutes.value:
      return { path: `/clients/${route.params.clientId}/instances/${route.params.instanceId ? `${route.params.instanceId}/` : ''}interactions/service_selector` };
    case localeRouteName.value.includes('needinteraction'):
      return { path: `${route.path.replace(/needinteractions\/\d+$/gi, 'interactions')}/service_selector` };
    case localeRouteName.value.includes('interaction'):
      return { path: `${route.path.slice(0, route.path.indexOf('/interactions') + 13)}/service_selector` };
    case localeRouteName.value.includes('events'):
      return { name: 'client.events.service_selector' };
    default:
      return { name: 'client.timeline.service_selector' };
  }
});

const targetQueryParams = computed(() => {
  if (route?.name?.toString().includes('assessments')) {
    return route.query;
  }
  if (route?.name?.toString().includes('events')) {
    return route.query;
  }
  let date = new Date();
  if (typeof route.query?.date === 'string') {
    date = new Date(route.query.date);
  }
  return {
    date: date.toISOString(),
    ...route.query,
  };
});

const tooltipText = computed(() => {
  if (route?.name?.toString().includes('assessments')) {
    return translate('assessment.add_new');
  }
  if (route?.name?.toString().includes('events')) {
    return translate('events.add_event');
  }
  return translate('service.add_new');
});

const addInteraction = () => {
  router.push(
    {
      ...targetRouteName.value,
      query: targetQueryParams.value,
    },
  );
};

const { userHasPermission } = useUserStore();
const canCreateInteractions = userHasPermission('createInteractions');
const hideAddBtn = computed(() => !canCreateInteractions || router.currentRoute.value.name === 'client.events.service_selector');

const buttonWrapperPosition = computed(() => (
  isMobileView.value ? 'relative' : 'absolute'
));
const tooltipPosition = computed(() => (isMobileView.value ? 'top' : 'bottom'));
useEventListener(window, 'resize', resizeHandler);

// Removing angular add buttons : new interaction, care plan, assessments and circle of care
hideElementsWithClassesNames(['top-right-add-btn ng-new-interaction-btn', 'top-right-add-btn add-multiple-compts-btn sp-add-button', 'top-right-add-btn sp-angular-button']);

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createBlock(BaseCircleButton, {
    class: "sp-add-button",
    top: "19px",
    right: "71px",
    left: "auto",
    position: buttonWrapperPosition.value,
    onClick: addInteraction
  }, null, 8, ["position"])), [
    [_vShow, !hideAddBtn.value],
    [_unref(vBaseTooltip), { tooltipText: tooltipText.value, position: tooltipPosition.value }]
  ])
}
}

})