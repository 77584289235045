import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["alt", "src"]

import {
  computed, PropType, Ref, StyleValue,
} from 'vue';
import { IconColor } from '@/_shared/types/iconColor';
import { IconPosition } from '@/_shared/types/iconPosition';
import { IconSize } from '@/_shared/types/iconSize';
import { IconAlign } from '@/_shared/types/iconAlign';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseIcon',
  props: {
  name: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
  },
  size: {
    type: String as PropType<IconSize>,
    default: 'lg',
  },
  align: {
    type: String as PropType<IconAlign>,
  },
  transparent: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  noHoverBackground: {
    type: Boolean,
    default: false,
  },
  compact: {
    type: Boolean,
    default: false,
  },
  closeButtonBlack: {
    type: Boolean,
    default: false,
  },
  backgroundShape: {
    type: String,
  },
  backgroundColor: {
    type: String as PropType<IconColor>,
  },
  foregroundColor: {
    type: String as PropType<IconColor>,
  },
  foregroundHoverColor: {
    type: String as PropType<IconColor>,
  },
  height: {
    type: String,
  },
  width: {
    type: String,
  },
  position: {
    type: String as PropType<IconPosition>,
  },
  active: {
    type: Boolean,
    default: false,
  },
  activePointed: {
    type: Boolean,
    default: false,
  },
  noHover: {
    type: Boolean,
    default: false,
  },
  square: {
    type: Boolean,
    default: false,
  },
  fontSize: {
    type: String,
  },
  padding: {
    type: String,
  },
  customCss: {
    type: String,
  },
  border: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const iconSource = computed(() => {
  try {
    if (props.name.includes('.svg')) {
      return props.name;
    }
    if (props.name === 'loading') {
      return require('../../assets/images/loader.svg');
    }
    return require(`../../assets/icons/${props.name}.svg`);
  } catch (e) {
    return props.name;
  }
});

const showSvgIcon = computed(() => !!iconSource.value?.includes('.svg'));

const getCssClass = computed(() => props.customCss || `
    ${props.size ? `fa-${props.size}` : ''}
    ${props.transparent ? 'transparent' : 'normal'}
    ${props.align ? `fa-pull-${props.align}` : ''}
    ${props.compact ? 'compact' : ''}
    noprint
    hover-${props.size}
    ${props.closeButtonBlack ? 'v-close-button-black' : ''}
    ${props.noHoverBackground ? 'v-no-hover-background' : ''}
    ${props.disabled && props.name.startsWith('nr-') ? 'v-nr-icon' : ''}
    ${props.disabled ? 'v-icon-disabled np-hover' : ''}
    ${props.noHover ? 'no-hover' : ''}
    ${props.square ? 'square' : ''}
    ${props.foregroundColor ? `foreground-color-${getColour(props.foregroundColor)}` : ''}
    ${props.backgroundColor ? `background-color-${getColour(props.backgroundColor)}` : ''}
    ${props.foregroundHoverColor ? `hover-color-${getColour(props.foregroundHoverColor)}` : ''}
    ${props.active ? 'active' : ''}
    ${props.activePointed ? 'active-pointed' : ''}
    ${props.padding ? `padding-${props.padding}` : ''}
    ${props.border ? 'border' : ''}
  `);

const getIcon = computed(() => {
  if (!props.name) {
    return '';
  }
  if (props.name.includes('nr-') || props.name.includes('nsx-') || props.name.includes('ncp-') || props.name.includes('.svg') || props.name.includes('v-icon-')) {
    return props.name;
  }
  return `fa fa-${props.name}`;
});

const getColour = (colour: string) => {
  if (colour === '$v-color-black') {
    return '#172B34';
  }
  return colour;
};

const getCssStyle: Ref<StyleValue | undefined> = computed(() => {
  const style: StyleValue = {
    'font-size': props.fontSize || props.height,
    position: props.position,
    padding: props.padding || '0',
  };
  if (!props.activePointed) {
    style.height = props.height;
    style.width = props.width;
    style['line-height'] = props.height;
  }
  if (props.name === 'loading') {
    style.animation = 'spin 1.2s linear infinite';
  }
  return style;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", null, [
    (showSvgIcon.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          alt: __props.alt,
          src: iconSource.value,
          class: _normalizeClass(getCssClass.value),
          style: _normalizeStyle(getCssStyle.value)
        }, null, 14, _hoisted_1))
      : (_openBlock(), _createElementBlock("i", {
          key: 1,
          class: _normalizeClass(`${getIcon.value} ${getCssClass.value}`),
          style: _normalizeStyle(getCssStyle.value)
        }, null, 6))
  ]))
}
}

})