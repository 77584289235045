import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-base-filter-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "v-base-filter-dropdown"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 0 }

import { computed, PropType, ref } from 'vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import type { DropdownFilterOption } from '@/_shared/types/dropdownFilterOption';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseFilterDropdown',
  props: {
  allOptions: {
    type: Array as PropType<DropdownFilterOption[]>,
    required: true,
  },
  currentOption: {
    type: Object as PropType<DropdownFilterOption>,
    default() {
      return {
        name: '',
        codename: '',
      };
    },
  },
},
  emits: ['valueChanged'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const props = __props;

const dropdownActive = ref(false);
const toggleDropdown = () => {
  dropdownActive.value = !dropdownActive.value;
};

const activeOption = ref(props.currentOption.codename);
const handleOptionClicked = (option?: DropdownFilterOption) => {
  if (!option) {
    return;
  }
  toggleDropdown();
  if (activeOption.value === option.codename) {
    return;
  }
  activeOption.value = option.codename;
  emit('valueChanged', sortedOptions.value[0]);
};

const sortedOptions = computed(() => {
  const selectedOption = props.allOptions.find((option) => option.codename === activeOption.value);
  return selectedOption ? [selectedOption, ...props.allOptions.filter((option) => option.codename !== activeOption.value)] : props.allOptions;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "v-base-filter-dropdown-selected v-base-filter-dropdown-option",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (__props.allOptions.length > 1? toggleDropdown(): () => {}))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["v-base-filter-dropdown-option-name", `v-base-filter-dropdown-option-${ sortedOptions.value[0]?.codename }`])
      }, [
        _createTextVNode(_toDisplayString(sortedOptions.value[0]?.name) + " ", 1),
        ('count' in sortedOptions.value[0]
          && sortedOptions.value[0].count !== undefined
          && sortedOptions.value[0].count >= 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "  (" + _toDisplayString(sortedOptions.value[0].count) + ") ", 1))
          : _createCommentVNode("", true)
      ], 2),
      _withDirectives(_createVNode(BaseIcon, {
        name: "nsx-font-icon--expand-bottom",
        noHover: "",
        compact: "",
        "foreground-color": "white",
        "background-color": "transparent"
      }, null, 512), [
        [_vShow, __props.allOptions.length > 1]
      ])
    ]),
    (dropdownActive.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedOptions.value, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              key: (option?.codename as string),
              class: "v-base-filter-dropdown-option",
              onClick: ($event: any) => (handleOptionClicked(option))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["v-base-filter-dropdown-option-name", `v-base-filter-dropdown-option-${ option?.codename }`])
              }, [
                _createTextVNode(_toDisplayString(option?.name) + " ", 1),
                ('count' in option && option.count !== undefined && option.count >= 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, "  (" + _toDisplayString(option.count) + ") ", 1))
                  : _createCommentVNode("", true)
              ], 2),
              (option === sortedOptions.value[0])
                ? (_openBlock(), _createBlock(BaseIcon, {
                    key: 0,
                    name: "nsx-font-icon--expand-top",
                    noHover: "",
                    compact: "",
                    "foreground-color": "white",
                    "background-color": "transparent"
                  }))
                : _createCommentVNode("", true)
            ], 8, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})