import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import BaseRightPanel from '@/_shared/components/general/BaseRightPanel.vue';
import useAnalyticsStore from '@/_shared/store/analytics';
import { computed } from 'vue';
import LeftPanelDropdownMenu from '@/dashboards/components/LeftPanelDropdownMenu.vue';
import DropdownOption from '@/_shared/components/buttons/types/DropdownOption';
import { storeToRefs } from 'pinia';
import useDataExport from '@/dashboards/services/dataExport';
import { useWindowSize } from '@vueuse/core';
import PowerBiEmbed from './PowerBiEmbed.vue';
import DashboardsSmallScreenError from './DashboardsSmallScreenError.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardsRightPanel',
  props: {
  category_id: {
    type: String,
    required: true,
  },
  subcategory_id: {
    type: String,
    required: false,
  },
},
  setup(__props) {

const { width } = useWindowSize();
const props = __props;

const analyticsStore = useAnalyticsStore();
const { downloadVisualsDataAsCSV, visualsData } = useDataExport();
const { embeddedReport } = storeToRefs(analyticsStore);
const report = computed(() => (props.category_id ? analyticsStore.getCategoryInfo(props.category_id) : null));

const printOrExport = (option: DropdownOption) => {
  if (option.codename === 'print') {
    embeddedReport.value?.print();
    return;
  }
  if (option.codename === 'export') {
    downloadVisualsDataAsCSV();
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(width) > 800)
      ? (_openBlock(), _createBlock(BaseRightPanel, _mergeProps({
          key: 0,
          withBGImg: false
        }, _ctx.$attrs), {
          default: _withCtx(() => [
            (report.value)
              ? (_openBlock(), _createBlock(PowerBiEmbed, {
                  key: 0,
                  workspaceId: report.value.workspaceId,
                  reportId: report.value.id,
                  embedUrl: report.value.embedUrl,
                  page: __props.subcategory_id
                }, null, 8, ["workspaceId", "reportId", "embedUrl", "page"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 16))
      : (_openBlock(), _createBlock(DashboardsSmallScreenError, { key: 1 })),
    (report.value)
      ? (_openBlock(), _createBlock(LeftPanelDropdownMenu, {
          key: 2,
          "export-disabled": !_unref(visualsData).length,
          onDropdownOptionSelected: printOrExport
        }, null, 8, ["export-disabled"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})