import { Person, personStore } from '@/_shared/store/people';
import { NeedReview } from '@/timeline/types/Need';
import { format } from 'date-fns';
import * as Locales from 'date-fns/locale';

export const formatNavigationDate = (date: string | null): string | null => {
  if (!date) {
    return null;
  }

  return format(new Date(date), 'yyyy-MM-dd');
};

export const formatDate = (date: string | null): string | null => {
  if (!date) {
    return null;
  }

  return format(new Date(date), 'dd/MM/yyyy');
};

export const formatTime = (date: string | null): string | null => {
  if (!date) {
    return null;
  }

  return format(new Date(date), 'HH:mm');
};

export const getResponsiblePeople = (ids: number[]): Person[] => personStore
  .people(ids)
  .sort((a, b) => {
    if (!a?.name) return -1;
    if (!b?.name) return 1;
    return (a.name).localeCompare(b.name);
  });

export const calculateRiskScoreClass = (score: number) => {
  if (score === 0) {
    return 'inactive';
  } if (score <= 2) {
    return 'success';
  } if (score === 3) {
    return 'warning';
  }
  return 'danger';
};

export const calculateRiskResultClass = (result: number) => {
  if (result === 0) {
    return 'inactive';
  } if (result <= 5) {
    return 'success';
  } if (result <= 10) {
    return 'warning';
  }
  return 'danger';
};

export const calculateLevelOfNeedClass = ((value: string | null) => {
  if (!value) { return 'black'; }

  const score = (value && parseInt(value, 10)) || 0;

  if (score === 0) {
    return 'inactive';
  } if (score <= 2) {
    return 'success';
  } if (score === 3) {
    return 'warning';
  }
  return 'danger';
});

export const shouldShowReview = (review: NeedReview, lastReviewId: number | null) => {
  if (lastReviewId) {
    return review.state !== 'planned' && review.id !== lastReviewId;
  }
  return review.state !== 'planned';
};

export const getYearMonthKey = (date: string) => {
  const locale = Object.values(Locales).find((x) => x.code === window.currentPerson?.language);
  const reviewDate = new Date(date);
  return {
    year: reviewDate.getFullYear(),
    month: format(reviewDate, 'MMM', { locale }),
  };
};

export const groupReviewsByYearAndMonth = (reviews: NeedReview[], lastReviewId: number | null) => {
  const grouped = reviews.filter((r) => shouldShowReview(r, lastReviewId))
    .filter((review) => !!review.finishAt)
    .reduce((acc: Record<number, Record<string, NeedReview[]>>, review) => {
      const { year, month } = getYearMonthKey(review.finishAt!);

      if (!acc[year]) {
        acc[year] = {};
      }

      if (!acc[year][month]) {
        acc[year][month] = [];
      }

      acc[year][month].push(review);

      return acc;
    }, {});

  const sortedYears = Object.entries(grouped)
    .map(([year, months]) => ({ year, months }))
    .sort((a, b) => +b.year - +a.year);

  return {
    lastYear: sortedYears[0],
    previousYears: sortedYears.slice(1),
  };
};
