import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-search-box" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]

import { BaseIcon } from '@/_shared/components';
import { PropType, ref } from 'vue';
import { IconColor } from '@/_shared/types/iconColor';
import { watchDebounced } from '@vueuse/core';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSearchText',
  props: {
  inputId: {
    type: [String, Number],
    required: true,
  },
  inputLabel: {
    type: String,
    default: '',
  },
  additionalStyles: {
    type: String,
    default: '',
  },
  iconColor: {
    type: String as PropType<IconColor>,
    default: 'cool-grey',
  },
},
  emits: ['search'],
  setup(__props, { emit: __emit }) {



const searchTerm = ref('');
const emit = __emit;
watchDebounced(
  searchTerm,
  () => emit('search', searchTerm.value),
  { debounce: 500 },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "v-search-text-filter-container",
    style: _normalizeStyle(__props.additionalStyles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(BaseIcon), {
        name: "search",
        alt: "search",
        size: "sm",
        foregroundColor: __props.iconColor
      }, null, 8, ["foregroundColor"]),
      _createElementVNode("label", {
        for: `s-${__props.inputId}`
      }, _toDisplayString(__props.inputLabel), 9, _hoisted_2),
      _withDirectives(_createElementVNode("input", {
        id: `s-${__props.inputId}`,
        class: "v-search-input",
        placeholder: "Search Reports...",
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchTerm).value = $event))
      }, null, 8, _hoisted_3), [
        [_vModelText, searchTerm.value]
      ])
    ])
  ], 4))
}
}

})