import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-highlight-outer"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 2,
  class: "v-highlight-large-area"
}

import { computed } from 'vue';
import blackPlus from '@/_shared/assets/images/body-map/black_plus.svg';
import bluePlus from '@/_shared/assets/images/body-map/blue_plus.svg';


export default /*@__PURE__*/_defineComponent({
  __name: 'LocationIcon',
  props: {
  locationNumber: {
    type: Number,
    required: false,
  },
  blue: {
    type: Boolean,
    required: false,
  },
  largeArea: {
    type: Boolean,
    default: false,
  },
  multipleInstances: {
    type: Boolean,
    default: false,
  },
  lozengeIcon: {
    type: Boolean,
    default: true,
  },
  placeholderOnly: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const cssProps = computed(() => ({
  '--width': props.lozengeIcon ? '32px' : '32px',
  '--background-color': props.blue ? '#66CCCC' : '#172B34FF',
  '--font-size': props.lozengeIcon ? '12px' : '12px',
  '--color': props.blue ? '#172B34FF' : '#FFF',
  '--background-color-print': props.blue ? 'grey' : 'black',
}));

const plusImg = props.blue ? bluePlus : blackPlus;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "v-body-map-icon",
    style: _normalizeStyle(cssProps.value)
  }, [
    (__props.lozengeIcon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (__props.multipleInstances)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "v-highlight-multiple",
          src: _unref(plusImg),
          alt: "plus"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (__props.largeArea)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "v-highlight",
      style: _normalizeStyle(cssProps.value)
    }, _toDisplayString(__props.locationNumber || ''), 5)
  ], 4))
}
}

})