import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-careplan-edit-item" }
const _hoisted_2 = { class: "v-careplan-edit-item-title" }
const _hoisted_3 = { class: "v-careplan-edit-item-content" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "v-careplan-edit-item-content-name" }

import {
  computed, PropType,
} from 'vue';
import { NeedProvidedServiceAttribute } from '@/timeline/types/Need';
import use from '@/_shared/compositionApi';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseInput from '@/_shared/components/inputs/BaseInput.vue';
import BaseSelect from '@/_shared/components/inputs/BaseSelect.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'EditProvidedServices',
  props: {
  items: {
    type: Object as PropType<NeedProvidedServiceAttribute[]>,
    required: true,
  },
  options: {
    type: Object as PropType<{ value: number, text: string }[]>,
    required: true,
  },
},
  emits: ['update:items'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();

const props = __props;

const emit = __emit;

const itemsProxy = computed({
  get: () => props.items,
  set: (newVal) => emit('update:items', newVal),
});

const addProvidedService = () => {
  itemsProxy.value.unshift({
    providedServiceId: 0,
    description: '',
  } as unknown as NeedProvidedServiceAttribute);
  emit('update:items', [...itemsProxy.value]);
};

const removeService = (service: NeedProvidedServiceAttribute, index: number) => {
  if (service.id) {
    service._destroy = true;
    emit('update:items', [...itemsProxy.value]);
  } else {
    emit('update:items', [...itemsProxy.value.splice(index, 1)]);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_unref(translate)('need.need_subscribed_service')) + " ", 1),
      _createVNode(BaseIcon, {
        name: "nr-icon-plus",
        onClick: addProvidedService
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (service, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: service.id
        }, [
          (!service._destroy)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(BaseIcon, {
                    name: "archive",
                    onClick: ($event: any) => (removeService(service, index)),
                    class: "v-careplan-edit-item-content-name-icon"
                  }, null, 8, ["onClick"]),
                  _createVNode(BaseSelect, {
                    border: true,
                    boldLabel: "",
                    labelWidth: "300px",
                    inputWidth: "50%",
                    options: __props.options,
                    modelValue: service.providedServiceId,
                    "onUpdate:modelValue": ($event: any) => ((service.providedServiceId) = $event)
                  }, null, 8, ["options", "modelValue", "onUpdate:modelValue"])
                ]),
                _createVNode(BaseInput, {
                  border: true,
                  textarea: true,
                  modelValue: service.description,
                  "onUpdate:modelValue": ($event: any) => ((service.description) = $event),
                  placeholder: _unref(translate)('need.placeholders.provided_service_description_placeholder')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}
}

})