import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "v-fixed-items" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "v-non-fixed-items" }
const _hoisted_4 = {
  key: 0,
  class: "v-scrollable-items"
}
const _hoisted_5 = { class: "v-timeline-section v-timeline-date" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

import {
  computed, onBeforeMount, onMounted, onUnmounted, PropType, ref, watchEffect,
} from 'vue';
import { onBeforeRouteUpdate, useRouter } from 'vue-router';
import useInteractionsStore from '@/_shared/store/interactions';
import IInteraction from '@/timeline/types/IInteraction';
import InstanceInteractionListEntry
  from '@/timeline/components/InteractionListEntry/InstanceInteractionListEntry.vue';
import useServiceFilteringStore from '@/_shared/store/serviceFiltering';
import { storeToRefs } from 'pinia';
import { BaseIcon } from '@/_shared/components';
import NoResultsMessage from '@/_shared/components/general/left_panel/NoResultsMessage.vue';
import use from '@/_shared/compositionApi';
import { formatInteractionDate } from '@/_shared/helpers/useDateTimeHelper';
import { NourishInstance } from '@/_shared/types/NourishInstance';

type InteractionGroup = {
  date: string;
  interactions: IInteraction[];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InstanceViewTimeline',
  props: {
  instanceId: {
    type: Number,
    required: true,
  },
  clientId: {
    type: Number,
    required: true,
  },
  searchTerm: {
    type: String,
    default: '',
  },
  selectedInstance: {
    type: Object as PropType<NourishInstance>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();
const {
  fetchBulkInteractions,
  searchForClient,
  interactionsSortByDate,
  getCurrentInteractionIdsForInstance,
  findInteraction,
  compareInteractionDate,
} = useInteractionsStore();
const { interactions } = storeToRefs(useInteractionsStore());
const selectedInstanceId = ref<number>();

const interactionsByDate = ref<InteractionGroup[]>([]);

const startInteraction = ref<IInteraction>();
const fixedStartInteraction = ref(true);
const showPreviousClicked = ref(false);
const router = useRouter();

const storeInstanceInteractions = computed(() => interactions.value.filter((i) => i.nourishInstanceId === props.selectedInstance.id).sort((a, b) => interactionsSortByDate(b, a)));

const currentInteractions = computed(() => getCurrentInteractionIdsForInstance(storeInstanceInteractions.value, props.selectedInstance).map((interactionId) => interactions.value.find((i) => i.id === interactionId) as IInteraction));

const remainingInteractionIds = computed(() => props.selectedInstance.interactions.filter((interaction) => storeInstanceInteractions.value.length > 0 && storeInstanceInteractions.value.find((i) => i.id === interaction.id) === undefined).map((i) => i.id as number));

const pastInteractions = computed(() => storeInstanceInteractions.value.filter((interaction) => !currentInteractions.value.includes(interaction) && interaction.id !== startInteraction.value?.id).sort((a, b) => interactionsSortByDate(a, b)));

const fetchInteractions = async (ids: (number | undefined)[]) => {
  // TODO: Align fetch from store with updated store structure
  if (ids.length) {
    await fetchBulkInteractions(ids.filter((id) => id) as number[], true, false);
  }
};

const pageLoading = ref(false);

const resetInteractionsState = () => {
  fixedStartInteraction.value = true;
  resetState();
  useInteractionsStore().$reset();
};

const resetState = () => {
  interactionsByDate.value = [];
};

const queryCurrentInstanceInteractions = async () => {
  if (props.selectedInstance.id === selectedInstanceId.value) return;

  selectedInstanceId.value = props.selectedInstance.id;
  resetState();
  let instanceInteractions: IInteraction[] = [];
  if (props.selectedInstance) instanceInteractions = props.selectedInstance.interactions;
  const idsToFetch = getCurrentInteractionIdsForInstance(instanceInteractions, props.selectedInstance);
  await fetchInteractions([props.selectedInstance.startInteractionId, ...idsToFetch]);
  startInteraction.value = findInteraction(props.selectedInstance.startInteractionId);
  currentInstance.value = {
    state: props.selectedInstance.state,
    subType: props.selectedInstance.subType,
    instanceId: props.selectedInstance.id,
  };
};

onBeforeMount(async () => {
  if (router.currentRoute.value.name === 'client.timeline.body_map.instance'
    || router.currentRoute.value.name === 'client.events.instance'
  ) {
    resetInteractionsState();
  }
  await queryCurrentInstanceInteractions();
});

onBeforeRouteUpdate(async () => {
  await queryCurrentInstanceInteractions();
});

const groupCurrentInteractions = (plannedInteractions: IInteraction[]) => {
  plannedInteractions
    .sort((a, b) => interactionsSortByDate(a, b))
    .forEach((interaction) => groupInteraction(interaction));
};

const groupInteraction = (interaction: IInteraction) => {
  const stripHour = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  const interactionDate = interaction.startAt || interaction.finishAt;
  if (interactionDate) {
    const group = interactionsByDate.value.find((g) => g.date === stripHour(interactionDate));
    if (group) {
      group.interactions.push(interaction);
    } else {
      interactionsByDate.value.push({
        date: stripHour(interactionDate),
        interactions: [interaction],
      });
    }
    interactionsByDate.value.forEach((g) => {
      g.interactions = [...new Set(g.interactions)];
    });
  }
};
watchEffect(() => groupCurrentInteractions(currentInteractions.value));

const fetchRemainingInteractionsBatch = async () => {
  showPreviousClicked.value = true;
  const idsToFetch = [] as number[];
  const entries = Math.min(10, remainingInteractionIds.value.length);
  for (let i = 0; i < entries; i += 1) idsToFetch.push(remainingInteractionIds.value[i]);
  pageLoading.value = true;
  await fetchInteractions(idsToFetch);
  fixedStartInteraction.value = false;
  pageLoading.value = false;
};

const serviceFilteringStore = useServiceFilteringStore();
const {
  instanceViewTimelineMounted, currentInstance,
} = storeToRefs(serviceFilteringStore);
// todo check what the new behaviour from place button
// todo   remove code from onmounted , to be able to use keepAlive -->
onMounted(async () => {
  instanceViewTimelineMounted.value = true;
  currentInstance.value = {
    state: props.selectedInstance.state,
    subType: props.selectedInstance.subType,
    instanceId: props.selectedInstance.id,
  };
});

onUnmounted(() => {
  instanceViewTimelineMounted.value = false;
  currentInstance.value = { state: '', subType: '', instanceId: undefined };
});

const sortedInteractionGroups = computed(() => [...interactionsByDate.value].sort((a, b) => {
  const aDate = new Date(a.date);
  const bDate = new Date(b.date);
  return compareInteractionDate(aDate, bDate);
}));

const organisationUnitId = window.currentOrganisationUnit.id;
const searchedInteractions = ref<IInteraction[]>([]);
watchEffect(async () => {
  if (props.searchTerm.length === 0 || !organisationUnitId) {
    searchedInteractions.value = [];
    return;
  }
  searchedInteractions.value = await searchForClient(organisationUnitId, props.clientId, props.searchTerm, null, props.instanceId);
});

watchEffect(() => {
  if (router.currentRoute.value.name === 'client.timeline.interaction' && showPreviousClicked.value) {
    fixedStartInteraction.value = false;
  }
});

const searchTermEmpty = computed(() => !props.searchTerm || (props.searchTerm && props.searchTerm.length === 0));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default"),
      (startInteraction.value && fixedStartInteraction.value && searchTermEmpty.value)
        ? (_openBlock(), _createBlock(InstanceInteractionListEntry, {
            key: 0,
            class: "v-instance-start-interaction",
            interaction: startInteraction.value
          }, null, 8, ["interaction"]))
        : _createCommentVNode("", true),
      (remainingInteractionIds.value.length && searchTermEmpty.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "v-interaction-divider-wrapper" }, [
              _createElementVNode("hr", { class: "v-interaction-divider" })
            ], -1)),
            (!pageLoading.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: fetchRemainingInteractionsBatch,
                  class: "v-show-previous-button"
                }, [
                  _createVNode(_unref(BaseIcon), {
                    name: "nsx-font-icon--timeline-dotted",
                    "no-hover": true,
                    foregroundColor: "white"
                  }),
                  _createTextVNode(" " + _toDisplayString(_unref(translate)('timeline.interaction.showPrevious')), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (startInteraction.value && !fixedStartInteraction.value && searchTermEmpty.value)
        ? (_openBlock(), _createBlock(InstanceInteractionListEntry, {
            key: 0,
            class: "v-instance-start-interaction",
            interaction: startInteraction.value
          }, null, 8, ["interaction"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pastInteractions.value, (interaction) => {
        return (_openBlock(), _createBlock(InstanceInteractionListEntry, {
          key: interaction.id!,
          class: "v-current-interaction sp-past-interaction",
          interaction: interaction
        }, null, 8, ["interaction"]))
      }), 128))
    ]),
    (searchTermEmpty.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedInteractionGroups.value, (interactionGroup) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "v-instance-current-interactions",
              key: interactionGroup.date
            }, [
              _createElementVNode("h4", _hoisted_5, _toDisplayString(_unref(formatInteractionDate)(interactionGroup.date)), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(interactionGroup.interactions, (interaction) => {
                return (_openBlock(), _createBlock(InstanceInteractionListEntry, {
                  key: interaction.id!,
                  class: "v-current-interaction sp-grouped-interaction",
                  interaction: interaction
                }, null, 8, ["interaction"]))
              }), 128))
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (!searchedInteractions.value || (searchedInteractions.value && searchedInteractions.value.length === 0))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(NoResultsMessage, { noResultsTerm: __props.searchTerm }, {
                  no_results_message: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(translate)('search.no_records_for')), 1)
                  ]),
                  _: 1
                }, 8, ["noResultsTerm"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchedInteractions.value, (interaction) => {
                  return (_openBlock(), _createBlock(InstanceInteractionListEntry, {
                    key: interaction.id!,
                    class: "v-current-interaction sp-searched-interaction",
                    interaction: interaction
                  }, null, 8, ["interaction"]))
                }), 128))
              ]))
        ]))
  ], 64))
}
}

})