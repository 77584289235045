import { ref } from 'vue';
import { defineStore } from 'pinia';
import use from '@/_shared/compositionApi';
import { filterTextToDisplay } from '../services/instanceFilterHelper';
import type { DropdownFilterOption } from '../types/dropdownFilterOption';
import type { NourishInstance } from '../types/NourishInstance';

export type TimelineFilters = Record<string, DropdownFilterOption>;

const useTimelineFiltersStore = defineStore('timelineFilters', () => {
  const { translate } = use.helpers();

  const isTimelineFilterOpen = ref(false);
  const timelineFilterText = ref(translate('filter.all'));

  const timelineSearchTerm = ref('');

  function toggleTimelineFilter() {
    isTimelineFilterOpen.value = !isTimelineFilterOpen.value;
  }

  const timelineFilters = ref<TimelineFilters>({
    // By default we only show new and active instances.
    state: { name: '', codename: 'new&active' },
    subType: { name: '', codename: 'all' },
  });

  function setTimelineFilters(newFilters: TimelineFilters) {
    timelineFilters.value = {
      ...timelineFilters.value,
      ...newFilters,
    };
  }

  const setTimelineFilterText = (filters: string[]) => {
    timelineFilterText.value = filterTextToDisplay(filters);
  };

  const filterTimelineInstancesByState = (instance: NourishInstance) => {
    const { codename } = timelineFilters.value.state;
    return codename === 'all'
      || (codename === 'new&active' && ['new', 'active'].includes(instance.state))
      || codename === instance.state;
  };

  const filterTimelineInstancesBySubType = (instance: NourishInstance) => {
    const { codename } = timelineFilters.value.subType;
    return codename === 'all'
      || codename === instance.subType;
  };

  const filterTimelineInstancesByStateAndSubtype = (instance: NourishInstance) => filterTimelineInstancesByState(instance) && filterTimelineInstancesBySubType(instance);

  const $reset = () => {
    isTimelineFilterOpen.value = false;
    timelineSearchTerm.value = '';
    setTimelineFilters({
      state: { name: '', codename: 'new&active' },
      subType: { name: '', codename: 'all' },
    });
  };

  return {
    isTimelineFilterOpen,
    toggleTimelineFilter,
    timelineFilters,
    setTimelineFilters,
    timelineFilterText,
    timelineSearchTerm,
    setTimelineFilterText,
    filterTimelineInstancesByState,
    filterTimelineInstancesBySubType,
    filterTimelineInstancesByStateAndSubtype,
    $reset,
  };
});
export default useTimelineFiltersStore;
