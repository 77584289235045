/* keep this updated in line with
app/assets/javascripts/angular/presenters/name_parser.js
lib/nourish/preferred_terms_helper.rb  */

import { clientStore } from '@/_shared/store/clients';
import { computed } from 'vue';

const addPreferredTermsToText = (clientId: number, text: string) => computed(() => {
  const preferredTerms = _getPreferredTerms(clientId);
  return _preferredTermsInText(text, preferredTerms) ? _replacePreferredTerms(text, preferredTerms) : text;
});

const _preferredTermsInText = (name: string, preferredTerms: Record<string, string>) => Object.keys(preferredTerms).some(((term) => name.includes(term)));

const _replacePreferredTerms = (text: string, preferredTerms: Record<string, string>) => {
  let newText = text;
  Object.entries(preferredTerms).forEach(([key, value]) => {
    const replaceRegEx = new RegExp(key, 'g');
    if (newText.includes(key)) newText = newText.replace(replaceRegEx, value);
  });
  return newText;
};

const _getPreferredTerms = (clientId: number) => ({
  '<first_name>': clientStore.preferredOrFirstName(clientId).value || 'client',
  '<client_term>': window.currentOrganisationUnit.client_term,
  '<carer_term>': window.currentOrganisationUnit.carer_term,
  '<care_plan_term>': window.currentOrganisationUnit.care_plan_term,
  '<event_term>': window.currentOrganisationUnit.event_term,
} as Record<string, string>);

export default addPreferredTermsToText;
