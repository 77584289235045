import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-spinner" }

import { onMounted, onUnmounted, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingSpinner',
  setup(__props) {

const iconList = [
  'v-icon-spinner_1',
  'v-icon-spinner_2',
  'v-icon-spinner_3',
  'v-icon-spinner_4',
];

const state = ref(0);
let stateChangeIntervalValueId: ReturnType<typeof setInterval> | undefined;

onMounted(() => {
  stateChangeIntervalValueId = setInterval(() => {
    state.value = (state.value + 1) % 4;
  }, 250);
});

onUnmounted(() => {
  clearInterval(stateChangeIntervalValueId);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(iconList[state.value])
    }, null, 2)
  ]))
}
}

})